import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import FormsSummary from 'modules/FormsSummary/Summary';
import SimpleTable from 'modules/Tables/SimpleTable';
import { getSummary } from 'helpers/form';
import Heading from 'components/Heading';
import ApprovalProfilePortfolioSummary from '@hubins/components/ApprovalProfilePortfolioSummary';
import ClientDocumentsAccordeon from '@hubins/components/ClientDocumentsAccordeon';
import { useTransactions } from 'queries/report';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import ReadMore from 'components/Accordeon/ReadMore';
import CompanyPersonnelPowersOfAttorney from '@hubins/components/CompanyPersonnelPowersOfAttorney';
import ExpandableTable from '@hubins/components/ExpandableTable';
import TofApprovalComponent from './TofApprovalComponent';
import { approvalTransactionHeaders } from 'helpers/batches';
import { getDefaultTransactionSourceOfFundsHeaders, getTransactionSourceOfWealthHeaders } from 'helpers/sof';
import { UTCIntoGMTPlusTwo } from 'helpers/date';
import __ from 'localisation';

const Tof = ({ portfolio_uuid: portfolioUuid, endDate, canEdit }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState(false);
  const [purposeSummary, setPurposeSummary] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [policyHolder, setPolicyHolder] = useState(false);
  const [signatories, setSignatories] = useState([]);
  const [beneficialOwners, setBeneficialOwners] = useState([]);
  const [risk, setRisk] = useState(false);
  const [sourceOfWealth, setSourceOfWealth] = useState(false);
  const [subAccounts, setSubAccounts] = useState(false);

  const {
    data: faTransactions,
    isLoading:
    faTransactionsLoading,
    isFetched: faTransactionsFetched
  } = useTransactions(
    '2010-01-01',
    endDate ? endDate : new Date().toISOString().slice(0, 10),
    portfolio
      ? portfolio.uuid : null
  );

  const fetchInitData = async () => {
    setLoading(true);
    try {
      const transactionsPromise = middleman.promisePost('/ql/crm/transactions/filter?withSecurity=true&withSources=true', {
        portfolioUuid,
        // status: 'pending',
        type: 'TOF',
        endDate: UTCIntoGMTPlusTwo(endDate)
      });

      const subAccountsPromise = middleman.get(`/ql/crm/portfolio/subaccounts/${portfolioUuid}`);

      const riskPromise = middleman.get(`/risk-assessment/${portfolioUuid}`);

      const portfolioPromise = middleman.get(`/portfolio/${portfolioUuid}`);

      const [transactionsRes, subAccountsRes, riskRes, portfolioRes] = await Promise.all([
        transactionsPromise,
        subAccountsPromise,
        riskPromise,
        portfolioPromise
      ]);

      const foundPolicyHolder = portfolioRes.data.policy_holder[0] || false;

      if (foundPolicyHolder && foundPolicyHolder.type === 'COMPANY') {
        const profileRes = await middleman.get(`/profile/${foundPolicyHolder.uuid}?with=powersofattorney`);
        setSignatories(profileRes.data.signatories);
        setBeneficialOwners(profileRes.data.beneficial_owners);
      }

      const riskCategory = riskRes.data;
      setRisk(riskCategory);
      setPolicyHolder(foundPolicyHolder);
      const isCompany = foundPolicyHolder.type === 'COMPANY';
      const purposeFormKey = isCompany ? 'c_da_purpose' : 'p_da_purpose';
      getSummary(purposeFormKey, foundPolicyHolder.uuid, portfolioUuid, setPurposeSummary, undefined, { endDate });
      const sourceOfWealthRes = await middleman.get(`/ql/crm/source-of-funds/${foundPolicyHolder.uuid}`);
      setSourceOfWealth(sourceOfWealthRes.data);
      setPortfolio(portfolioRes.data);
      setTransactions(transactionsRes.data.filter(transaction => transaction.status !== 'draft'));
      setSubAccounts(subAccountsRes.data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInitData();
  }, []);

  const updateTransaction = async (transaction) => {
    const updatedTransactions = transactions.map((t) => {
      if (t.uuid === transaction.uuid) {
        return transaction;
      }
      return t;
    });
    setTransactions(updatedTransactions);
  };

  const reset = () => {
    setTransactions(false);
    setPurposeSummary(false);
    setPortfolio(false);
    setPolicyHolder(false);
    setSignatories([]);
    setBeneficialOwners([]);
    setRisk(false);
    setSourceOfWealth(false);
    setSubAccounts(false);
    fetchInitData();
  };

  if (loading) return (<LoadingBox />);

  return (
    <>
      <ApprovalProfilePortfolioSummary portfolioUuid={portfolio?.uuid} />
      <div className='maxwidth s-top-xl'>
        <CompanyPersonnelPowersOfAttorney
          signatories={signatories}
          beneficialOwners={beneficialOwners}
          profileId={policyHolder?.id}
        />
      </div>
      <ClientDocumentsAccordeon
        portfolioUuid={portfolio?.uuid}
        profileUuid={policyHolder?.uuid}
        policyHolderName={policyHolder?.name}
      />
      {purposeSummary ? (
        <FormsSummary
          className="s-top-xl"
          content={purposeSummary.content}
          response={purposeSummary.response}
          title={__("purpose")}
        />
      ) : <LoadingBox />}
      <Heading size="4" className="s-top-xl s-bottom-lg">
        {__('sof')}
      </Heading>
      <SimpleTable data={sourceOfWealth} headers={getDefaultTransactionSourceOfFundsHeaders(risk, sourceOfWealth)} footer />
      {sourceOfWealth && (
        <>
          <Heading size="4" className="s-bottom-lg s-top-xl">
            {__('sow')}
          </Heading>
          <SimpleTable
            className="s-top-xl"
            headers={getTransactionSourceOfWealthHeaders(sourceOfWealth)}
            data={sourceOfWealth}
            title="Source of wealth"
            footer
          />
        </>
      )}
      <Heading size="4" className='s-bottom-md'>
        {__('tof')}
      </Heading>
      <ExpandableTable
        headers={approvalTransactionHeaders}
        data={transactions}
        component={(row) => (
          <TofApprovalComponent
            row={row}
            subAccounts={subAccounts}
            updateTransaction={updateTransaction}
            sourceOfFunds={sourceOfWealth}
            risk={risk}
            reset={reset}
            canEdit={canEdit}
          />
        )}
      />
      <Heading size="4" className={'s-top-xl s-bottom-lg'}>
        {__('transactions')}
      </Heading>

      <ReadMore collapsedHeight={100}>
        <TransactionsTable
          data={
            (faTransactionsFetched && faTransactions && faTransactions.transactions)
              ? faTransactions.transactions
              : []}
          isLoading={faTransactionsLoading}
          currency={portfolio.currency}
        />
      </ReadMore>
    </>
  );
};

export default Tof;
