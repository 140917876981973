import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';

import './index.scss';


const SummaryTable = (props) => {

  return (
    <div className={`summary-table__wrap ${props.className} s-bottom-xl`}>
      <header className="summary-table__header s-bottom-md">
        <Heading  size={props.titleSize}>{props.heading || ''}</Heading>{props.editButton}
        {props.editRoute && (
          <Button icon="Common File Text by Streamlinehq">
            Ändra svar
          </Button>
        )}
      </header>
      <ul className="summary-table">
        {props.children}
      </ul>
    </div>
  );
};

SummaryTable.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

SummaryTable.defaultProps = {
  title: 'Summary table',
  className: '',
  titleSize: 5,
};

export default SummaryTable;
