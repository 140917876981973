import React from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import LoadingBar from 'components/LoadingIndicators';

const headers = [
  {
    Header: '',
    accessor: 'title',
  },
  {
    Header: '',
    accessor: 'value',
  },
];

type Props = {
  portfolio: any;
  loading: boolean;
  risk: number;
  importedFmaCategory: number;
};

const BusinessProfilePortfolioTable = ({ portfolio, loading, risk, importedFmaCategory }: Props) => {

  const data = [
    {
      title: 'Policy Number',
      value: portfolio.short_name
    },
    {
      title: 'Risk Categorization',
      value: loading ? <LoadingBar small /> : risk
    },
    {
      title: 'Imported Risk Categorization from FA',
      value: loading ? <LoadingBar small /> : importedFmaCategory
    },
    ...portfolio.policy_holder.map((policyHolder, index) => ({
      title: `Policy Holder ${index + 1}`,
      value: policyHolder.name
    })),
    {
      title: 'Is the policyholder the same as the beneficial owner of the policy?',
      value: 'Yes'
    },

  ]

  return (
    <div className='s-top-xl s-bottom-xl'>
      <SimpleTable
        headers={headers}
        data={data}
      />
    </div>
  )
};

export default BusinessProfilePortfolioTable;