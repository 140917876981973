import React, { useState, useEffect } from 'react';
import { usePortfolioFilter } from 'queries/portfolios';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { defaultHeaders, loadingState } from 'helpers/portfolio';

const PositiveAccountBalancePaidPremiumPortfolios = () => {
  const [page, setPage] = useState(-1);
  const [amountOfPages, setAmountOfPages] = useState(1);
  const [initiated, setInitiated] = useState(false);
  const { data, isLoading } = usePortfolioFilter(initiated, 'INV', undefined, undefined, true, page + 1);

  const handlePageFetch = (index) => {
    setPage(index);
    setInitiated(true);
  };

  useEffect(() => {
    if (data) {
      setAmountOfPages(data.last_page);
    }
  }, [data]);

  return (
    <ApiPaginationTable
      data={isLoading ? loadingState : data?.data || []}
      headers={defaultHeaders}
      pageCount={amountOfPages}
      index={page}
      fetchData={handlePageFetch}
    />
  )

};

export default PositiveAccountBalancePaidPremiumPortfolios;