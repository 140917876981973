import React, { Component } from 'react';
import ImageUpload from 'components/ImageUpload';


class CaseImageEdit extends Component {

  constructor(props){
    super(props);
    this.state = {
      type: 'caseImage',
    };
    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(id, value){
    value.type = this.state.type;
    this.props.callback(id, value);
  }

  render() {
    const valueProps = 'value' in this.props ? this.props.value : {};
    return (
      <ImageUpload
        text={this.props.text}
        folder={this.props.folder}
        {...valueProps}
        callback={this.updateValue}
        id={this.props.id}
      />

    );
  }

}

export default CaseImageEdit;
