import React, { useMemo } from 'react';
import { usePaidPremiumAccount, useSubAccounts } from 'queries/portfolios';
import FaTransactionsView from '../../../../projects/backoffice/views/FaTransactions';
import { LoadingBox } from 'components/LoadingIndicators';

const FaTransactions = ({ profileUuid, portfolioUuid }: { profileUuid: string, portfolioUuid: string }) => {
  const { data: paidPremiumAccount, isFetched: paidPremiumAccountFetched } = usePaidPremiumAccount(profileUuid);
  const { data: subAccounts, isFetched: subAccountsFetched } = useSubAccounts(portfolioUuid);

  const portfolioUuids = useMemo(() => {
    if (!paidPremiumAccountFetched || !subAccountsFetched) {
      return null;
    }
    const uuids = [portfolioUuid];
    if (paidPremiumAccount) {
      uuids.push(paidPremiumAccount.uuid);
    }
    if (subAccounts) {
      subAccounts.forEach((subAccount) => {
        uuids.push(subAccount.uuid);
      });
    }
    return uuids;
  }, [subAccountsFetched, paidPremiumAccountFetched, paidPremiumAccount, subAccounts])

  if (!portfolioUuids) {
    return <LoadingBox />;
  }

  return (
    <FaTransactionsView portfolioUuids={portfolioUuids} />
  );
};

export default FaTransactions;