import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import __ from 'localisation';
import InputCurrency from '@hubins/components/Input/Currency';
import Input from '@hubins/components/Input';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import Button, { ButtonWrap } from '@hubins/components/Button';
import DateInput from '@hubins/components/DateInput';
import ErrorMessage from 'components/ErrorMessage';
import Heading from 'components/Heading';
import { paidPremiumTransactionOptions } from 'helpers/transactions';
import UnitPriceInput from '@hubins/components/UnitPriceInput';
import ExchangeRateInput from '@hubins/components/ExchangeRateInput';
import TradeAmountInput from '@hubins/components/TradeAmountInput';
import DecimalInput from '@hubins/components/DecimalInput';

const EditBatchTofTransaction = ({ transaction, setTransaction, refetch, canEdit = false }) => {

  const [units, setUnits] = useState<any>(0);
  const [origin, setOrigin] = useState<any>('');
  const [originAccount, setOriginAccount] = useState<any>('');
  const [subAccount, setSubAccount] = useState('');
  const [subAccounts, setSubAccounts] = useState<{ uuid: string, short_name: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [accruedInterest, setAccruedInterest] = useState<any>(0);
  const [paidPremiumTransactions, setPaidPremiumTransactions] = useState<any>([]);
  const [paidPremiumTransaction, setPaidPremiumTransaction] = useState<any>(null);
  const [unitPrice, setUnitPrice] = useState<any>(0);
  const [transactionDate, setTransactionDate] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [exchangeRate, setExchangeRate] = useState<any>(0);
  const [contactName, setContactName] = useState<any>('');
  const [contactEmail, setContactEmail] = useState<any>('');
  const [contactPhone, setContactPhone] = useState<any>('');
  // const [currency, setCurrency] = useState<any>('');
  const [initiated, setInitiated] = useState(false);

  const alreadyCompleted = transaction?.status === 'completed';

  const fetchSubAccounts = async (portfolioUuid: string) => {
    setLoading(true);
    const subPromise = middleman.get(`/ql/crm/portfolio/subaccounts/${portfolioUuid}`);
    const ppPromise = middleman.get(`/ql/crm/transactions/paid-premiums/${portfolioUuid}`);
    const [subRes, ppRes] = await Promise.all([subPromise, ppPromise]);
    setPaidPremiumTransactions(paidPremiumTransactionOptions(ppRes.data));
    setSubAccounts(subRes.data);
    setLoading(false);
  };

  useEffect(() => {
    if (transaction) {
      fetchSubAccounts(transaction.portfolio_uuid);
      setUnits(transaction.units);
      setOrigin(transaction.origin);
      setOriginAccount(transaction.origin_account);
      setSubAccount(transaction.sub_portfolio_uuid);
      setAccruedInterest(transaction.accrued_interest);
      setPaidPremiumTransaction(transaction.paid_premium_transaction_id);
      setUnitPrice(transaction.unit_price);
      setTransactionDate(transaction.transaction_date);
      setContactName(transaction.contact_name);
      setContactEmail(transaction.contact_email);
      setContactPhone(transaction.contact_phone);
      // setCurrency(transaction.currency);
      setExchangeRate(transaction.exchange_rate);
      setInitiated(true);
      setError(null);
    }
  }, [transaction]);


  const save = async (status = '') => {
    setSaving(true);
    try {
      await middleman.post(`/ql/crm/transaction/update/${transaction.uuid}`, {
        units,
        origin,
        originAccount,
        subPortfolioUuid: subAccount,
        accruedInterest,
        unitPrice,
        // currency,
        exchangeRate,
        contactName,
        contactEmail,
        contactPhone,
        paidPremiumTransactionId: paidPremiumTransaction,
        transactionDate,
        status: status || undefined,
      });
      setTransaction(null);
      refetch();
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
    }
    setSaving(false);
  }

  return (
    <Modal
      open={!!transaction}
      callback={setTransaction}
    >
      {(loading || !transaction || !initiated) ? (
        <LoadingBox />
      ) : (
      <Columns>
        <Column l="12">
          <Heading size={5}>
              {`${__('transaction_singular')} ${transaction.id}`}
          </Heading>
        </Column>
        <Column l="6">
          <Input
            label={__('policy')}
            value={transaction.portfolio.short_name}
            disabled={true}
          />
        </Column>
        <Column l="6">
          <Input
            label={'Security'}
            value={transaction.security.name}
            disabled={true}
          />
        </Column>
        <Column l="6">
          <InputCurrency
            label={__('choose_amount')}
            value={transaction?.amount ? transaction.amount / 100 : 0}
            suffix='SEK'
            disabled={true}
          />
          </Column>
          <Column l="6">
            <DateInput
              label={__('choose_transaction_date')}
              value={transactionDate}
              callback={setTransactionDate}
              disabled={!canEdit || alreadyCompleted}
            />
        </Column>
        <Column l="6">
          <UnitPriceInput
            securityId={transaction?.security?.id}
            callback={setUnitPrice}
            value={unitPrice}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <DecimalInput
            label={__('choose_units')}
            value={units}
            callback={setUnits}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
            <Input
              label={__('currency')}
              value={transaction.security.currency}
              disabled={true}
            />
        </Column>
        <Column l="6">
          <ExchangeRateInput
            currency={transaction?.security?.currency}
            callback={setExchangeRate}
            value={exchangeRate}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Input
            label={__('choose_origin')}
            value={origin}
            callback={setOrigin}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Input
            label={__('choose_origin_account')}
            value={originAccount}
            callback={setOriginAccount}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Dropdown
            label={__('choose_sub_account')}
            value={subAccount}
            placeholder={__('choose_sub_account')}
            options={subAccounts ? subAccounts.map((item) => ({ id: item.uuid, title: item.short_name })) : []}
            callback={setSubAccount}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>

        <Column l="6">
          <Input
            label={__('accrued_interest_label')}
            value={accruedInterest}
            callback={setAccruedInterest}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Dropdown
            label={__('choose_paid_premium_transaction')}
            value={paidPremiumTransaction}
            placeholder={__('choose_paid_premium')}
            options={paidPremiumTransactions}
            callback={setPaidPremiumTransaction}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <TradeAmountInput
            units={units}
            unitPrice={unitPrice}
            exchangeRate={exchangeRate}
            currency={'SEK'}
            multiplier={transaction?.security?.multiplier}
            accruedInterest={accruedInterest === '?' ? 0 : accruedInterest}
          />
        </Column>
        <Column l="6">
          <Input
            label={__('contact_name')}
            value={contactName}
            callback={setContactName}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Input
            label={__('contact_email')}
            value={contactEmail}
            callback={setContactEmail}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column l="6">
          <Input
            label={__('contact_phone')}
            value={contactPhone}
            callback={setContactPhone}
            disabled={!canEdit || alreadyCompleted}
          />
        </Column>
        <Column s="12">
          <ButtonWrap>
            <Button
              className="success"
              onClick={() => save('ready-for-execution')}
              loading={saving}
              disabled={saving || transaction.status === 'ready-for-execution' || !canEdit || alreadyCompleted}
            >
              {__('ready-for-execution')}
            </Button>
            <Button
              className="cta"
              onClick={() => save()}
              loading={saving}
              disabled={saving || !canEdit || alreadyCompleted}
            >
              {__('save')}
            </Button>
            <Button onClick={() => setTransaction(null)}>
              {__('cancel')}
            </Button>
          </ButtonWrap>
        </Column>
        <Column s="12">
          <ErrorMessage text={error} showError={!!error} />
        </Column>
      </Columns>
      )}
    </Modal>
  );
};

export default EditBatchTofTransaction;