import React, { useState, useCallback, useEffect } from 'react';
// TODO: figure out how to add alias for below
import { SourceOfFundType } from '../../../packages/types/sourceOfFunds';
import { useCollapse } from 'react-collapsed';
import AccordeonButton from '../AccordeonButton';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';
import __ from 'localisation';
import Box from 'components/Box';
import { getForm } from 'helpers/form'
import SettingsExpandableFormSummary from '@hubins/components/SettingsExpandableFormSummary';
import { SofChangeNotesList } from '@hubins/components/SofChangeNotes';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink/AuthDownloadLink';
import { getMoney } from 'helpers';
import { dynamicHeaders } from 'helpers/sof';

type Props = {
  source: SourceOfFundType
}

const summaryHeaders = [
  {
    Header: '',
    accessor: 'type',
  },
  {
    Header: '',
    accessor: 'value',
  },
];

const proofOfFundHeaders = [
  {
    Header: __('download'),
    accessor: 'download'
  },
  {
    Header: __('name'),
    accessor: 'name'
  },
  {
    Header: __('created_at'),
    accessor: 'created_at'
  },
];

const SourceOfFundListItem = ({ source } : Props) => {

  const [summary, setSummary] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const handleClick = useCallback(() => { setExpanded(!isExpanded); }, [isExpanded, setExpanded]);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const eventHeaders = dynamicHeaders(source?.source?.events[0]);

  const summaryData = [
    {
      type: __('sow'),
      value: getMoney(source.source.amounts.source_of_wealth),
    },
    {
      type: __('sof'),
      value: getMoney(source.source.amounts.source_of_fund),
    },
    {
      type: __('available_amount'),
      value: getMoney(source.availableAmount),
    },
    {
      type: __('used_amount'),
      value: getMoney(source.usedAmount),
    }
  ];

  const fetchForm = async () => {
    const formContent = await getForm(
      source.form.key,
      source.form.type,
      source.profile_uuid,
      null,
      { version: source.form.version });
    formContent.response = source.form.response;
    setSummary(formContent);
  }

  useEffect(() => {
    fetchForm();
  }, [])

  const proofOfFundsTableData = source.proofOfFunds ? source.proofOfFunds.map((file) => {
    return {
      ...file,
      download: (<AuthDownloadLink
        className="transparent button-icon small s-top-sm"
        key={file.uuid}
        url={`/ql/crm/source-of-funds/download/${source.profile_uuid}/${source.uuid}/${file.uuid}`}
        filename={file.name}
      />
      ),
    };
  }) : [];

  return (
    <>
      <Box className="border">
        <AccordeonButton {...getToggleProps({onClick: handleClick})} className="hover-pointer">
          <Heading element="span" size="5" className="">
            {__(`sof_${source?.type}`)} {source?.source?.name
              ? (<span className="body-small s-left-s c-neutral-5" style={{ fontWeight: "400" }}>{source?.source?.name}</span>)
              : ''}
          </Heading>
        </AccordeonButton>
        <div {...getCollapseProps()}>
          {summary && (
            <SettingsExpandableFormSummary summary={summary} title={__("form_response")} className="s-top-xl" titleSize={5} />
          )}
          <SimpleTable
            headers={eventHeaders}
            data={source.source.events}
          />
          <Heading size="4" className="s-bottom-m s-top-xl">{__('summary')}</Heading>
          <SimpleTable
            headers={summaryHeaders}
            data={summaryData}
          />
          <Heading size="4" className="s-bottom-m s-top-xl">{__('pof')}</Heading>
          <SimpleTable
            headers={proofOfFundHeaders}
            data={proofOfFundsTableData}
          />
          <SofChangeNotesList changeNotes={source.changeNotes} />
        </div>
      </Box>
    </>
  )
};

export default SourceOfFundListItem;