import React, { useState, useEffect } from 'react';
import DocumentsList from './DocumentsList';
import middleman from 'helpers/middleman';


const PrivateDocuments = ({ tags, excludeTag, table, title, compact, className, children, portfolio, portfolioUuid, profileUuid }) => {
  const [documents, setDocuments] = useState(false);

  const getDocumentsInfo = (response) => {
    let newDocuments = response.data[0].documents;
    if (tags) {
      newDocuments = newDocuments.filter(
        doc => doc.tags.includes(tags)
      );
    }
    if (excludeTag) {
      newDocuments = newDocuments.filter(
        doc => !doc.tagsAsSet.includes(excludeTag)
      );
    }
    return newDocuments;
  };

  const fetchDocuments = async () => {
    setDocuments(false);
    const uri = (portfolio ? '/v1/fa/portfolio/documents/' : '/v1/fa/user/documents/') + portfolioUuid + (profileUuid ? `/${profileUuid}` : '');
    const docRes = await middleman.promiseGet(uri).then((res) => {
      return getDocumentsInfo(res);
    });
    setDocuments(docRes);
  };

  useEffect(() => {
    fetchDocuments();
  }, [portfolioUuid]);

  return (
    <DocumentsList
      documents={documents}
      auth={true}
      table={table}
      title={title}
      compact={compact}
      className={className}
    >
      {children}
    </DocumentsList>
  );
};

PrivateDocuments.defaultProps = {
  excludeTag: 'internalDocument',
};

export default PrivateDocuments;
