import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
import LoadingPage from 'views/LoadingPage';
import Icon from '@hubins/components/IconNew';
import SelectDropdown from 'components/SelectDropdown';
import { Column, Columns } from 'components/Columns';
import SortableList from 'components/SortableList';
import CaseTextEditor from 'components/CaseText/edit';
import CaseColumnsEdit from 'modules/CaseColumns/edit';
import CaseTableEdit from 'components/CaseTable/edit';
import CaseStatsTableEdit from 'components/CaseStatsTable/edit';
import CaseImageEdit from 'components/CaseImage/edit';
import CaseVideoEdit from 'components/CaseVideo/edit';
import CaseSliderEdit from 'modules/CaseSlider/edit';
import DocumentsEdit from 'components/Documents/edit';
import EditComponent from './EditComponent';
import CopyCase from './CopyCase';
import ImageUpload from 'components/ImageUpload';
import CaseFixedFooter from 'modules/CaseFixedFooter';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';
import { __plural } from 'localisation';
import CreateCmsCase from 'modules/CreateCmsCase';
import Heading from 'components/Heading';
import middleman from 'helpers/middleman';
import { useCallback } from 'react';

const EditLoaded = (props) => {

  const { objectid } = props.match.params;

  const [items, setItems] = useState([]);
  const [language, setLanguage] = useState(props.match.params.language);
  const [status, setStatus] = useState('draft');
  const [security, setSecurity] = useState(false);
  const [contentState, setContentState] = useState({});
  const [content, setContent] = useState(false);
  const [preview, setPreview] = useState(false);

  const fetchData = async () => {
    try {
      const contentRes = await middleman.get(`/v1/fa/securities/content/${objectid}/${language}/undefined`);
      setContent(contentRes.data); // TODO, set content.hero.value to '/assets/illustrations/investments_empty.svg' if missing
    } catch (e) {
      console.error('Error fetching content: ', e);
      setContent({});
    }
    try {
      const securityRes = await middleman.get(`/securities/${objectid}/show`);
      setSecurity(securityRes.data);
    } catch (e) {
      console.error('Error fetching security: ', e);
    }


  };

  const updateState = useCallback((id, value) => {
    setContentState((prevContentState) => {
      return {
        ...prevContentState,
        [id]: value
      };
    });
  }, [contentState]);

  const addComponent = (component) => {
    setItems((prevItems) => {
      return [
        ...prevItems,
        component
      ];
    });
  };

  const restoreItems = () => {
    let obj = content.content;
    obj.forEach((item) => {
      switch (item.type) {
        case 'text':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Text block</Heading>
                <CaseTextEditor value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'textColumn':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Two columns text blocks</Heading>
                <CaseColumnsEdit value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'detail-table':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Table</Heading>
                <CaseTableEdit value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'stats-table':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Small tables</Heading>
                <CaseStatsTableEdit value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'documents':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Documents</Heading>
                <DocumentsEdit value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'caseImage':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Image</Heading>
                <CaseImageEdit folder={objectid} value={item} text={item.text} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'caseVideo':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Video</Heading>
                <CaseVideoEdit value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        case 'caseSlider':
          addComponent({
            id: item.id,
            content:
              <>
                <Heading size="5">Slideshow</Heading>
                <CaseSliderEdit folder={objectid} value={item} id={item.id} callback={updateState} />
              </>
          });
          break;
        default:
      }
    });
  };

  const replaceState = (newItems) => {
    setItems(newItems);
  };

  const statusChange = (e) => {
    let value = e;
    if (typeof e.target !== "undefined") {
      value = e.target.value;
    }
    setStatus(value);
  };

  useEffect(() => {
    if (content && typeof content.content !== "undefined") {
      restoreItems();
      statusChange(content.status);
    }
  }, [content]);

  const resetView = () => {
    setItems([]);
    setContent(false);
    setContentState({});
  };

  useEffect(() => {
    if (language) {
      resetView();
      fetchData();
    }
  }, [language]);


  const formatData = () => {
    // create a new data not to mutate the old state
    let sortedData = [];

    items.forEach(item => {
      let theItem = contentState[item.id];
      sortedData.push({ ...theItem });
    });

    sortedData.forEach((item) => {

      switch (item.type) {
        case 'text':
          item.value = props.convertToRaw(item.value.getCurrentContent());
          break;
        case 'textColumn':
          item.right = props.convertToRaw(item.right.getCurrentContent());
          item.left = props.convertToRaw(item.left.getCurrentContent());
          break;
        case 'detail-table':
          item.rows = item.rows.map(row => {
            return { ...row, text: props.convertToRaw(row.text.getCurrentContent()) };
          });
          break;
        default:
          break;
      }
    });

    let description = { ...contentState['case_desc'] };
    description.value = props.convertToRaw(description.value.getCurrentContent());

    const contentData = {
      content: sortedData,
      logo: contentState.logo,
      hero: contentState.hero,
      description,
      created_by: props.user.name,
      last_edited_by: props.user.name,
      status: status,
    };
    return contentData;
  };

  const exportData = () => {
    return formatData();
  };

  const storeData = async () => {
    const contentData = formatData();
    await middleman.promisePost(`/ql/crm/securities/content/${objectid}/${language}`, contentData);
    resetView();
    fetchData();
  };

  const languageChange = (value) => {
    setLanguage(value);
    props.history.push(`/dashboard/cms/${objectid}/${value}`);
  };

  const togglePreview = () => {
    setPreview(!preview);
  };


  const importContent = (newContent) => {
    resetView();
    setContent(newContent);
  };


  const descriptionProps = typeof content.content === "undefined" ? {} : { value: content.description };
  const logoProps = typeof content.content === "undefined" ? {} : { value: content.logo.value, text: content.logo.text };
  const heroProps = typeof content.content === "undefined" ? {} : { value: content.hero.value, text: content.hero.text };

  if (content) {
    return (
      <>
        <CopyCase
          exportData={exportData}
          replaceState={replaceState}
          importContent={importContent}
        />
        <Columns className="justify-center">
          <Column s="12" m="6">
            <Heading>
              {security ?
                security.name : 'Securitie: ' + objectid
              }
            </Heading>
          </Column>

          <Column s="12" m="6" className="right">
            {!content.created_by &&
                <p className="body-small clarification">
                  This security has no content yet
                </p>
            }
            {content.created_by &&
                <p className="body-small clarification">
                  <em>Created by:</em> <strong><Icon icon="user" /> {content.created_by}</strong>, <Icon icon="Time Clock Circle by Streamlinehq" /> {content.created_at}<br />
                </p>
            }
            {content.last_edited_by &&
                <p className="body-small clarification">
                  <em>Last edited by:</em> <strong><Icon icon="user" /> {content.last_edited_by}</strong>, <Icon icon="Time Clock Circle by Streamlinehq" /> {content.updated_at}<br />
                </p>
            }
          </Column>
        </Columns>

        <section className="section-padding">
          <Columns>
            <Column xs="6">
              <Heading size="4">Language:</Heading>
              <SelectDropdown value={language} onChange={languageChange}>
                <option value="FI">Finnish</option>
                <option value="NO">Norwegian</option>
                <option value="SE">Swedish</option>
                <option value="EN">English</option>
              </SelectDropdown>
            </Column>
            <Column xs="6">
              <Heading size="4">Status:</Heading>
              <select value={status} onChange={statusChange}>
                <option value="publish">publish</option>
                <option value="draft">draft</option>
              </select>
            </Column>

            <Column xs="6">
              <Heading size="4">Security loggo:</Heading>
              <ImageUpload folder={objectid} id="logo" {...logoProps} callback={updateState} />
            </Column>

            <Column xs="6">
              <Heading size="4">Hero image:</Heading>
              <ImageUpload folder={objectid} id="hero" {...heroProps} callback={updateState} />
            </Column>

          </Columns>
        </section>

        <section className="section-padding">
          <Heading size="4">Ingress:</Heading>
          <CaseTextEditor id="case_desc" {...descriptionProps} callback={updateState} large boxDesign={false} />
        </section>

        <section className="section-padding">
          <Heading size="4">Content:</Heading>
          <SortableList
            updateState={replaceState}
            items={items}
            addComponents={
              <div className="section-padding edit-components">
                <Heading element="h5" size="5"><Icon icon="add" /> Add component</Heading>
                <div className="button__wrap">
                  <EditComponent
                    items={items}
                    name="Text block"
                    content={<CaseTextEditor callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-wysiwyg.svg"
                  />

                  <EditComponent
                    items={items}
                    name={<>Two columns<br />text blocks</>}
                    content={<CaseColumnsEdit callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-text-columns.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Table"
                    content={<CaseTableEdit callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-table.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Small tables"
                    content={<CaseStatsTableEdit callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-tables.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Documents"
                    content={<DocumentsEdit callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-documents.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Slideshow"
                    content={<CaseSliderEdit callback={updateState} folder={objectid} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-slideshow.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Single image"
                    content={<CaseImageEdit folder={objectid} callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-image.svg"
                  />

                  <EditComponent
                    items={items}
                    name="Video"
                    content={<CaseVideoEdit callback={updateState} />}
                    addComponent={addComponent}
                    image="/assets/illustrations/cms-component-video.svg"
                  />

                </div>
              </div>
            }
          />
          {preview &&
              <div className="cms-preview" style={
                {
                  position: 'fixed',
                  top: 0, left: 0, right: 0, bottom: 0,
                  overflow: 'auto',
                  zIndex: 10,
                  background: '#fff'
                }}>
                {security && items && contentState.hero && (
                  <CreateCmsCase
                    id={objectid}
                    heroImage={contentState.hero.value ? contentState.hero.value : '/assets/illustrations/investments_empty.svg'}
                    logo={contentState.logo.value}
                    heroTitle={security.name}
                    interest={security.coupon}
                    period={__plural('month_string_short', false, { number: security.periodInMonths })}
                    maxVol={`${security.maxVolume}M`}
                    security={security}
                    description={
                      props.convertToRaw(
                        contentState.case_desc.value.getCurrentContent()
                      )
                    }
                    caseData={formatData().content}
                    open={true}
                    type={security.type}
                    securityCode={security.securityCode}
                    currency={security.currency}
                    ms_diff={security.msDiff}
                    hideFooter={true}
                    new
                  />
                )}
              </div>
          }
          <CaseFixedFooter
            onClick={storeData}
            buttonText={status === 'draft' ? 'Save draft' : 'Publish'}
            buttonStyle="cta"
            secondButtonText={preview ? "Close preview" : "Preview"}
            secondOnClick={togglePreview}
            secondButtonExternal={true}
            open={props.open}
          />
        </section>
      </>
    );
  } else {
    return (
      <LoadingPage />
    );
  }


};


const Edit = Loadable.Map({
  loader: {
    EditorState: () => import('draft-js/lib/EditorState'),
    convertToRaw: () => import('draft-js/lib/convertFromDraftStateToRaw'),
  },

  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <EditLoaded {...props}
      EditorState={loaded.EditorState.default}
      convertToRaw={loaded.convertToRaw.default}
    />;
  },
});
export default Edit;
