import React, { useState } from 'react';
import useTabs from 'hooks/useTabs';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import AuthSettings from './AuthSettings';

const tabs = [
  'auth'
];

const Settings = ({ location }) => {
  const [index, setIndex] = useState(0);
  useTabs(setIndex, index, tabs);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list">
        <Tab className="heading heading--4 line-tabs__tab">Authentication</Tab>
      </TabList>
      <TabPanel className={`line-tabs__tab-panel`}>
        <AuthSettings />
      </TabPanel>
    </Tabs>
  );
};

export default Settings;
