import React, { useState } from 'react';
import Modal from 'components/Modal';
import Button, { ButtonWrap } from '@hubins/components/Button';
import TextArea from 'components/Textarea';
import middleman from 'helpers/middleman';
import FileUpload from 'components/FileUpload';
import MultipleDropdown from '@hubins/components/MultipleDropdown';
import { channelOptions } from 'helpers/comments';
import __ from 'localisation';



type AddCommentProps = {
  closeModal: () => void;
  refetch: () => void;
  replyToComment?: any;
  model: string;
  modelUuid: string;
  editComment?: any;
  presetChannels?: string[];
}

const AddComment = ({
  closeModal,
  refetch,
  replyToComment,
  model,
  modelUuid,
  editComment,
  presetChannels,
}: AddCommentProps) => {
  const [comment, setComment] = useState(editComment ? editComment.commentBody : '');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState((editComment && editComment?.files) ? editComment?.files : []);
  const [channels, setChannels] = useState(editComment?.channels || presetChannels || []);

  const handleSubmit = async () => {
    setLoading(true);
    try {

      const body: any = {
        commentBody: comment,
        parentCommentUuid: replyToComment?.uuid || editComment?.parentCommentUuid || undefined,
        channel: channels && channels.length > 0 ? channels : undefined,
      };

      if (files && files.length > 0) {
        const filePromises = files.map(async (file) => {
          if (file.uuid) {
            return file.uuid;
          }
          const formData = new FormData();
          formData.append('file', file);
          return middleman.post(`/crm/comments/upload`, formData).then(r => r.data).catch(() => false);
        });
        const fileUuids = await Promise.all(filePromises);
        body.files = fileUuids.filter((file) => file !== false);
      }

      if (editComment) {
        await middleman.put(`/crm/comments/${model}/${modelUuid}/${editComment.uuid}`, body);
      } else {
        await middleman.post(`/crm/comments/${model}/${modelUuid}`, body);
      }
      refetch();
      closeModal();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  return (
    <Modal
      open
      callback={closeModal}
    >
      {replyToComment && (
        <>
          <p>{__('replying_to')}: <strong>{replyToComment.authorDisplayName}</strong></p>
          <div className="neutral-1 box-padding s-top-sm s-bottom-xl">
            <p>{replyToComment.commentBody}</p>
          </div>
        </>
      )}
      <MultipleDropdown
        label={__('view_rights')}
        options={channelOptions}
        values={channels}
        callback={setChannels}
      />
      <TextArea
        optionsState={{
          value: comment,
          error: false,
        }}
        label={__("Comment")}
        onChange={setComment}
      />
      <FileUpload
        controlled
        value={files}
        callback={setFiles}
      />
      <ButtonWrap>
        <Button
          onClick={handleSubmit}
          className="cta"
          loading={loading}
          disabled={loading}
        >
          {__('add_comment')}
        </Button>
        <Button
          onClick={closeModal}
          disabled={loading}
        >
          {__('cancel')}
        </Button>
      </ButtonWrap>
    </Modal>
  )
};

export default AddComment;