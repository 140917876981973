import React, { Component, Fragment } from 'react';
import { Columns, Column } from 'components/Columns';
import Button from '@hubins/components/Button';
import Icon from '@hubins/components/IconNew';
import ImageUpload from 'components/ImageUpload';
import { __ } from 'localisation';

class CaseSliderEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: 'caseSlider',
      id: this.props.id,
      slides: 'value' in this.props ?
        this.props.value.slides :
        [{
          type: 'image',
          value: '',
          text: ""
        }]
    };
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleRowType = this.handleRowType.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.state.id, {
      type: this.state.type,
      id: this.state.id,
      slides: this.state.slides
    });
  }

  handleRowType(value, index) {
    let newState = this.state.slides;
    newState[index].type = value;
    newState[index].value = '';
    this.setState({
      slides: newState
    });
  }

  updateValue(id, value, index) {
    let newState = this.state.slides;
    if (typeof value.value === "undefined") {
      newState[index].value = value;
    } else {
      newState[index].value = value.value;
      newState[index].text = value.text;
    }
    this.setState({
      slides: newState
    });

    this.props.callback(this.state.id, {
      type: this.state.type,
      id,
      slides: newState
    });

  }


  addRow() {
    let stateRows = this.state.slides;
    let newRow = { type: 'image', value: '' };
    stateRows.push(newRow);

    this.setState({
      slides: stateRows
    });

    this.props.callback(this.props.id, {
      type: this.state.type,
      id: this.state.id,
      slides: stateRows
    });

  }

  removeRow() {
    let stateRows = this.state.slides;
    stateRows.pop();

    this.setState({
      slides: stateRows
    });

    this.props.callback(this.props.id, {
      type: this.state.type,
      id: this.state.id,
      slides: stateRows
    });
  }


  renderRows() {
    return this.state.slides.map((row, index) => {
      const valueProps = 'value' in this.props ? { value: this.state.slides[index].value, text: this.state.slides[index].text } : {};
      return (
        <Columns key={index} className={index % 2 === 0 ? '' : 'neutral-1'}>
          <Column s="4">
            <label>
              <div className="label">{__('image_or_video')}?</div>
              <select className="select" onChange={(e) => { this.handleRowType(e.target.value, index); }} value={this.state.slides[index].type}>
                <option value="image">{__('image')}</option>
                <option value="video">__{('video')}</option>
              </select>
            </label>
          </Column>
          <Column s="8">
            {this.state.slides[index].type === "image" ? (
              <ImageUpload
                folder={this.props.folder}
                {...valueProps}
                callback={this.updateValue}
                id={this.props.id}
                index={index}
              />
            ) : (
              <input className="input" placeholder="Vimeo URL" onChange={(e) => { this.updateValue(this.props.id, e.target.value, index); }} value={this.state.slides[index].value} />
            )}
          </Column>
        </Columns>
      );
    });
  }

  render() {
    return (
      <Fragment>
        {this.renderRows()}
        <div className="button__wrap center">
          <Button className="spacing withicon withicon--left" onClick={this.addRow}><Icon icon="add" /> {__('add_row')}</Button>
          <Button className="spacing withicon withicon--left" onClick={this.removeRow}><Icon icon="subtract" /> {__('remove_row')}</Button>
        </div>
      </Fragment>
    );
  }

}

export default CaseSliderEdit;
