import React, { useState, Fragment } from 'react';
import FormHistory from 'modules/FormHistory';
import Dropdown from '@hubins/components/Dropdown';
import __ from 'localisation';

const tabs = [
  {
    id: 'due_dilligence_crossover',
    title: 'Due Diligence Crossover'
  },
  {
    id: 'policy_approval',
    title: 'Policy Approval'
  },
  {
    id: 'sof',
    title: 'Source of funds approval'
  },
  {
    id: 'deposit',
    title: 'Premium approval'
  }
];

const DueDiligenceResponses = ({ profileUuid, portfolioUuid })  =>{

  const [selected, setSelected] = useState(false);

  return (
    <>
      <Dropdown
        className={"input-max-width"}
        label={__('form')}
        options={tabs}
        value={selected}
        callback={setSelected}
        placeholder={__('choose_form')}
      />
      {selected && (
        <Fragment key={selected}>
          <FormHistory
            formKey={selected}
            type="approval"
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            portfolioOrProfile={true}
          />
        </Fragment>
      )}
    </>
  );
};
export default DueDiligenceResponses;
