import apiConnector from "api-connector";
import axios from "axios";

const middlemanURL: string = import.meta.env.VITE_MIDDLEMAN_URL;

let middleman = apiConnector(middlemanURL + "/api");


interface UserProfileRolePayload {
    userUuid: string;
    roleName: string;
}

interface PolicyProfileRolePayload {
    profileUuid: string;
    roleName: string;
}

type RoleData = {
    name: string;
    national_identity_number: string;
    uuid: string;
    email: string;
}
type BrokerIndexResponse = {
    id: number;
    name: string;
    email: string;
    national_identity_number: string;
    uuid: string;
    royc_email: string;
    created_at: string;
    updated_at: string;
}

type PolicyRolesResponse = {
    life_assured: RoleData[];
    policy_holder: RoleData[];
    beneficial: RoleData[];
    asset_manager: RoleData[];
    view_only_users: RoleData[];
}

type ProfileRolesResponse = {
    roles: RoleData[];
}

type Role = {
    id: number;
    name: string;
    guard_name: string;
    created_at: string;
    updated_at: string;
}

type User = {
    id: number;
    name: string;
    email: string;
    uuid: string;
    roles: RoleData[];
    created_at: string;
    updated_at: string;
}



const enhancedMiddleman = {
    ...middleman,
    getProfileRoles: async (profileUuid: string): Promise<ProfileRolesResponse> => {
        const res = await middleman.get(`/userprofileroles/${profileUuid}`);
        return res.data as ProfileRolesResponse;
    },
    addUserProfileRole: async (profileUuid: string, userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.post(`/userprofileroles/${profileUuid}`, payload);
    },
    removeUserProfileRole: async (profileUuid: string, userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.promisePost(`/userprofileroles/delete/${profileUuid}`, payload);
    },
    getPolicyRoles: async (portfolioUuid: string): Promise<PolicyRolesResponse> => {
        const response = await middleman.get(`/policyroles/${portfolioUuid}`);
        return response.data as PolicyRolesResponse;
    },
    addPolicyProfileRole: async (portfolioUuid: string, profileUuid: string, roleName: string): Promise<any> => {
        const payload: PolicyProfileRolePayload = { profileUuid, roleName };
        return await middleman.post(`/policyroles/${portfolioUuid}`, payload);
    },
    removePolicyProfileRole: async (portfolioUuid: string, profileUuid: string, roleName: string): Promise<any> => {
        const payload: PolicyProfileRolePayload = { profileUuid, roleName };
        return await middleman.promisePost(`/policyroles/delete/${portfolioUuid}`, payload);
    },
    getBrokers: async (): Promise<BrokerIndexResponse[]> => {
        const response = await middleman.get("/crm/brokers");
        return response.data as BrokerIndexResponse[];
    },
    getUserRoles: async (): Promise<Role[]> => {
        const response = await middleman.get(`/roles?type=user`);
        return response.data as Role[];
    },
    getRolesForUser: async (userUuid: string): Promise<Role[]> => {
        const response = await middleman.get(`/ql/crm/users/${userUuid}/roles`);
        return response.data as Role[];
    },
    getUser: async (userUuid: string): Promise<User> => {
        const response = await middleman.get(`/users/${userUuid}`);
        return response.data as User;
    },
    addUserRole: async (userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.post(`/ql/crm/users/${userUuid}/role`, payload);
    },
    removeUserRole: async (userUuid: string, roleName: string): Promise<any> => {
        const payload: UserProfileRolePayload = { userUuid, roleName };
        return await middleman.post(`/ql/crm/users/${userUuid}/role/remove`, payload);
    },
};

export const csrf = (): Promise<any> => {
    const csrf_url: string = middlemanURL + "/sanctum/csrf-cookie";
    return axios.get(csrf_url, { withCredentials: true });
};

export default enhancedMiddleman;