import React, { Component, Fragment } from 'react';
import TextEditor from 'components/TextEditor';
// import { EditorState, convertFromRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';
import Checkbox from 'components/Checkbox';
import cmsBoxTrueImgUrl from 'assets/illustrations/cms-box-true.svg';
import cmsBoxFalseImgUrl from 'assets/illustrations/cms-box-false.svg';

class CaseTextEditorLoaded extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: 'value' in this.props ? props.EditorState.createWithContent(props.convertFromRaw(this.props.value.value)) :
      props.EditorState.createEmpty(),
      type: 'text',
      id: this.props.id,
      box: this.props.value && typeof this.props.value.box !== 'undefined' ? this.props.value.box : true,
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.props.id, { type: 'text', value: this.state.value, id: this.state.id, box: this.state.box });
  }

  //draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //This is how we convert editorState to html string

  onEditorStateChange(editorState) {

    const newValue = {
      type: 'text',
      value: editorState,
      id: this.state.id,
      box: this.state.box,
    };

    this.setState({
      value: editorState
    });

    this.props.callback(this.props.id, newValue);
  }

  onCheckboxChange(value) {
    const newValue = {
      type: 'text',
      value: this.state.value,
      id: this.state.id,
      box: value,
    };

    this.setState({
      value: this.state.value,
      box: value,
    });

    this.props.callback(this.props.id, newValue);
  }


  render() {
    return (
      <Fragment>
        {this.props.boxDesign &&
        <Checkbox
          className="section-padding-small"
          optionsState={{ value: this.state.box }}
          // heading="Bekräftelse"
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Box design. Ex:
              {this.state.box ?
                <img src={cmsBoxTrueImgUrl} width="70px" style={{ width: 70, display: 'inline-block', marginLeft: 10, boxShadow: '0px 2px 20px 0 rgba(174, 181, 216, 0.2)' }} alt="Box design true" /> :
                <img src={cmsBoxFalseImgUrl} width="70px" style={{ width: 70, display: 'inline-block', marginLeft: 10, boxShadow: '0px 2px 20px 0 rgba(174, 181, 216, 0.2)' }} alt="Box design false" />
              }
            </span>
          }
          id="box"
          callback={(value) => this.onCheckboxChange(value)}
        />
        }
        <TextEditor
          extended
          large={this.props.large}
          editorState={this.state.value}
          onEditorStateChange={this.onEditorStateChange}
        />
      </Fragment>
    );
  }
}

CaseTextEditorLoaded.defaultProps = {
  boxDesign: true,
};

const CaseTextEditor = Loadable.Map({
  loader: {
    EditorState: () => import('draft-js/lib/EditorState'),
    convertFromRaw: () => import('draft-js/lib/convertFromRawToDraftState'),
  },
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <CaseTextEditorLoaded {...props}
      EditorState={loaded.EditorState.default}
      convertFromRaw={loaded.convertFromRaw.default}
      test="lol"
    />;
  },
});


export default CaseTextEditor;
