import React, { useEffect, useState } from 'react';
import { useBatches } from 'queries/batches';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { Column, Columns } from 'components/Columns';
import Dropdown from '@hubins/components/Dropdown';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import { batchHeaders, batchLoadingState } from 'helpers/batches';

const FilterBatches = () => {

  const [page, setPage] = useState<number>(-1);
  const [amountOfPages, setAmountOfPages] = useState<number>(1);
  const [initiated, setInitiated] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [filterType, setFilterType] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string>('');

  const { data, isLoading, isFetched } = useBatches(filterType, filterStatus, page + 1);

  useEffect(() => {
    if (data) {
      setAmountOfPages(data.last_page);
    }
  }, [data]);

  const search = () => {
    setFilterType(type);
    setFilterStatus(status);
  }

  const handlePageFetch = (index) => {
    if (!initiated) {
      setInitiated(true);
    }
    setPage(index);
  };

  return (
    <Columns>
      <Column l="6">
        <Dropdown
          placeholder={__('type')}
          label={__('type')}
          options={[{
            id: 'TOF',
            title: 'TOF'
          }]}
          value={type}
          callback={setType}
        />
      </Column>
      <Column l="6">
        <Dropdown
          placeholder={__('status')}
          label={__('status')}
          options={[
            {
              id: '',
              title: __('all')
            },
            {
              id: 'pending',
              title: __('pending')
            },
            {
              id: 'denied',
              title: __('denied')
            },
            {
              id: 'approved',
              title: __('completed')
            }
          ]}
          value={status}
          callback={setStatus}
        />
      </Column>
      <Column s="12">
        <Button onClick={search}>{__('search')}</Button>
      </Column>
      <Column s="12">
        <ApiPaginationTable
          data={isLoading || !isFetched ? batchLoadingState : data?.data || []}
          headers={batchHeaders}
          pageCount={amountOfPages}
          index={page}
          fetchData={handlePageFetch}
        />
      </Column>
    </Columns>
  )
};

export default FilterBatches;