import React, { useState } from 'react';
import { __ } from 'localisation';
import { Columns, Column } from 'components/Columns';
import Heading from 'components/Heading';
import RangeDatePicker from 'modules/RangeDatePicker';
import { useReport, useTransactions, useHoldings } from '../../../../packages/queries/report';
import AccountsTable from 'modules/Tables/AccountsTable';
import DistributionTable from 'modules/Tables/DistributionTable';
import EndowmentTable from 'modules/Tables/EndowmentTable';
import HoldingsTable from 'modules/Tables/Holdings';
import InsuranceFees from 'modules/Tables/InsuranceFees';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import Button from '@hubins/components/Button';
import LoadingBar from 'components/LoadingIndicators';
import middleman from 'helpers/middleman';
import PolicyCard from 'components/PolicyCard';
import { formatDate, firstDateOfYear, getMoney } from 'helpers';

const Report = ({ portfolioUuid, portfolio }) => {

  const [startDate, setStartDate] = useState(portfolio.start_date ? firstDateOfYear(portfolio.start_date) : '2020-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [pdfSuccess, setPdfSuccess] = useState(false);
  const { data: report, isLoading: reportLoading, isFetched } = useReport(startDate, endDate, portfolioUuid);
  const { data: transactions, isLoading: transactionsLoading, isFetched: transactionsIsFetched } = useTransactions(startDate, endDate, portfolioUuid);
  const { data: holdings, isLoading: holdingsLoading, isFetched: holdingsIsFetched } = useHoldings(startDate, endDate, portfolioUuid);

  const datePickerCallback = (from, to) => {

    if (from instanceof Date) {
      from = formatDate(from.toISOString());
    }

    if (to instanceof Date) {
      to = formatDate(to.toISOString());
    }

    setStartDate(from);
    setEndDate(to);

  };

  const generatePdf = async () => {
    try {
      setPdfLoading(true);
      setPdfError(false);
      setPdfSuccess(false);
      await middleman.promisePost(`/crm/report/pdf/${portfolioUuid}`, { startDate: startDate, endDate: endDate });
      setPdfSuccess(true);
      setPdfLoading(false);
    } catch (e) {
      setPdfLoading(false);
      setPdfError(true);
    }
  };

  return (
    <Columns>
      <Column s="12">
        <RangeDatePicker
          from={startDate}
          to={endDate}
          onClick={datePickerCallback}
          loading={status === 'loading'}
        />
      </Column>
      <Column s="12" m="6" l="6">
        <PolicyCard
          portfolio={portfolio}
        />
      </Column>
      <Column s="12" m="6" l="6">
        <p className='maxwidth s-top-xl'>
          <strong>
            {__('important_information')}
          </strong>
          <br />
          {__('report_intro_text')}
        </p>
        <Button
          className='cta'
          onClick={generatePdf}
          loading={pdfLoading}
          disabled={pdfLoading}
        >
          Generera PDF
        </Button>
        {pdfSuccess && <p className='c-success'>Din pdf håller på att genereras. Du kommer få ett mail när den är färdig och du hittar rapporten under fliken Dokument.</p>}
        {pdfError && <p className='c-danger'>Något gick fel, vänligen försök igen.</p>}
      </Column>
      <Heading size="4" className="s-top-m">
        Totalt värde
      </Heading>
      <Heading size="2" className="s-bottom-m">
        {reportLoading ? <LoadingBar /> : getMoney(report?.marketValue)}
      </Heading>
      <Column s="12" m="6" l="6">
        <Heading size="4" className="section-padding">
          {__('liquid_funds')}
        </Heading>
        <AccountsTable
          currency={isFetched ? report.accounts.currency : ''}
          isLoading={reportLoading}
          accounts={isFetched ? report.accounts : []}
        />
      </Column>
      <Column s="12" m="6" l="6">
        <Heading size="4" className="section-padding">
          {__('access_law')}
        </Heading>
        <DistributionTable
          data={isFetched ? report.distribution : []}
          isLoading={reportLoading}
          currency={isFetched ? report.accounts.currency : ''}
          marketValue={isFetched ? report.marketValue : 0}
        />
      </Column>
      <Column s="12">
        <Heading size="4" className="section-padding">
          {__('endowment_title')}
        </Heading>
        <EndowmentTable
          endowment={isFetched ? report.endowment : []}
          transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
          isLoading={reportLoading || transactionsLoading}
          currency={isFetched ? report.accounts.currency : ''}
        />
      </Column>
      <Column s="12">
        <HoldingsTable
          holdings={holdings}
          loading={holdingsLoading}
          currency={isFetched ? report.accounts.currency : ''}
          report
        />
      </Column>
      <Column s="12">
        <InsuranceFees
          endowment={isFetched ? report.endowment : []}
          transactionsEndowment={transactionsIsFetched ? transactions.endowment : []}
          isLoading={reportLoading || transactionsLoading}
          currency={isFetched ? report.accounts.currency : ''}
        />
      </Column>
      <Column s="12">
        <Heading size="4" className="section-padding">
          {__('Transactions')}
        </Heading>
        <TransactionsTable
          data={transactionsIsFetched ? transactions.transactions : []}
          isLoading={transactionsLoading}
          currency={portfolio.currency}
        />
      </Column>
    </Columns>
  );

};

export default Report;
