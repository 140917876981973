import React from 'react';
import __ from 'localisation';
import middleman from './middleman';
import Button from '@hubins/components/Button';
import { getMoney, formatDate } from 'helpers';
import { calculateTransactionTradeAmount } from './transactions';
import LoadingBar from 'components/LoadingIndicators';

export const getBatchStatus = (batch: any) => {
  if (!batch) return __('pending');
  const { hubins_status, quantum_status, status } = batch;
  if (status === 'approved') return __('completed');
  if (status === 'denied') return __('denied');
  if (hubins_status) return __(hubins_status);
  if (quantum_status) return __(quantum_status);
  return __('pending');
}

export const getBatchStatusColor = (status: string) => {
  if (status === 'approved') return 'c-success';
  if (status === 'denied') return 'c-danger';
  return 'c-info';
}

export const defaultHeadersWithoutPortfolios = [
  {
    Header: __('investment'),
    accessor: 'security.name'
  },
  {
    Header: __('isin'),
    accessor: 'security.isinCode'
  },
  {
    Header: __('trade_amount'),
    accessor: 'amount',
    Cell: ({ row }) => getMoney(calculateTransactionTradeAmount(row.original), 'SEK')
  },
  {
    Header: __('units'),
    accessor: 'units'
  },
  {
    Header: __('origin'),
    accessor: 'origin'
  },
  {
    Header: __('origin_account'),
    accessor: 'origin_account'
  },
]

export const approvalTransactionHeaders = [
  {
    Header: __('status'),
    accessor: 'approval_status',
    Cell: ({ value }) => {
      const isCompleted = value === 'pending-approval';
      const theStatus = value;
      return <span className={isCompleted ? 'c-success' : ''}>{theStatus}</span>
    }
  },
  ...defaultHeadersWithoutPortfolios
]

export const defaultHeaders = [
  {
    Header: __('policy_holder'),
    accessor: 'portfolio.policy_holder',
    Cell: ({ value }) => {
      return value && Array.isArray(value) ? value.map((item) => item.name).join(', ') : value;
    }
  },
  {
    Header: __('portfolio'),
    accessor: 'portfolio.short_name'
  },
  {
    Header: __('order_type'),
    accessor: 'type',
    Cell: ({ value }) => __(value)
  },
  ...defaultHeadersWithoutPortfolios,
  {
    Header: __('approval_status'),
    accessor: 'approval_status',
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at',
    Cell: ({ value }) => value ? formatDate(value, true) : ''
  }
];

export const defaultHeadersWithIds = [
  {
    Header: 'ID',
    accessor: 'id',
    maxWidth: 70,
  },
  {
    Header: 'Batch',
    accessor: 'batch_uuid',
    maxWidth: 70,
  },
  ...defaultHeaders
];

const batchLoadingObj = {
  id: <LoadingBar medium />,
  name: <LoadingBar medium />,
  hubins_status: <LoadingBar medium />,
  quantum_status: <LoadingBar medium />,
  status: <LoadingBar medium />,
  // updated_at: <LoadingBar medium />,
}

export const batchLoadingState = [
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
  batchLoadingObj,
];

export const deleteBatch = async (batchUuid: string, refetch: () => void) => {
  if (window.confirm(__('are_you_sure_you_want_to_delete_this_batch'))) {
    await middleman.promiseDelete(`/ql/crm/batches/delete/${batchUuid}`);
    refetch();
  }
}

export const batchHeaders = [
  {
    Header: '',
    accessor: 'open',
    Cell: ({ row }) => (
      <Button href={`/dashboard/batches/${row.original.uuid}`}>
        {__('open')}
      </Button>
    )
  },
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: __('name'),
    accessor: 'name'
  },
  {
    Header: __('status'),
    accessor: 'status',
    Cell: ({ row }) => <span className={getBatchStatusColor(row.original.status)}>{getBatchStatus(row.original)}</span>
  },
  {
    Header: __('approval_status'),
    accessor: 'approval_status',
    Cell: ({ row }) => {
      const transactions = row?.original?.transactions;
      if (!transactions || transactions.length === 0) return '';
      const allTransactionsApproved = transactions.every((transaction) => transaction.approval_status === 'approved');
      return allTransactionsApproved ? __('approved') : __('pending');
    }
  },
  {
    Header: __('updated_at'),
    accessor: 'updated_at',
    Cell: ({ value }) => value ? formatDate(value, true) : ''
  }
];

export const batchHeadersWithDelete = (refetch: () => void) => [
  {
    Header: '',
    accessor: 'remove',
    Cell: ({ row }) => (
      <Button onClick={() => deleteBatch(row.original.uuid, refetch)} onlyIcon icon="Bin by Streamlinehq" />
    )
  },
  ...batchHeaders
]

export const removeFromBatch = async (transactionUuid, refetch?: () => void) => {
  await middleman.promiseDelete(`/ql/crm/batches/remove/${transactionUuid}`);
  if (refetch) {
    refetch();
  }
}


export const batchListHeaders = (refetch: () => void) => [
    {
      Header: '',
      accessor: 'remove',
      Cell: ({ row }) => (
        <Button onClick={() => removeFromBatch(row.original.uuid, refetch)} onlyIcon icon="Bin by Streamlinehq" />
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: __('trade_amount'),
      accessor: 'amount'
    },
    {
      Header: __('investment'),
      accessor: 'security.name'
    },
  ]

export const defaultHeadersWithRemoveAndEdit = (refetch: () => void, setTransaction: (transaction: any) => void) => [
    {
      Header: '',
      accessor: 'remove',
      Cell: ({ row }) => (
        <Button onClick={() => removeFromBatch(row.original.uuid, refetch)} onlyIcon icon="Bin by Streamlinehq" />
      )
    },
    {
      Header: '',
      accessor: 'edit',
      Cell: ({ row }) => (
        <Button onClick={() => setTransaction(row.original)} onlyIcon icon="pencil write 2" />
      )
    },
    {
      Header: __('transaction_date'),
      accessor: 'transaction_date',
    },
    {
      Header: __('status'),
      accessor: 'status',
      Cell: ({ value }) => <span className={value === 'ready-for-execution' ? 'c-success' : ''}>{__(value)}</span>,
    },
    ...defaultHeaders
  ];

  export const  getHashByType = (type: string) => {
    switch (type) {
      case 'TOF':
        return '#tof';
      case 'TO':
        return '#investments';
      default:
        return '';
    }
  }

  export const hubinsStatusOptions = () => {
    return [
      {
        id: 'on_hold',
        title: __('on_hold')
      },
      {
        id: 'waiting_on_client',
        title: __('waiting_on_client')
      },
      {
        id: 'denied_by_ql',
        title: __('denied_by_ql')
      },
      {
        id: 'waiting_on_tof_approval',
        title: __('waiting_on_tof_approval')
      },
      {
        id: 'waiting_on_ql_signature',
        title: __('waiting_on_ql_signature')
      },
      {
        id: 'signed_by_ql',
        title: __('signed_by_ql')
      },
      {
        id: 'sent_to_counterparty',
        title: __('sent_to_counterparty')
      },
      {
        id: 'add_settlement_instructions',
        title: __('add_settlement_instructions')
      },
      {
        id: 'waiting_on_settlement',
        title: __('waiting_on_settlement')
      },
      {
        id: 'investment_received_ownership_confirmed',
        title: __('investment_received_ownership_confirmed')
      },
      {
        id: 'approved',
        title: __('approved')
      },
    ]
  };

  export const quantumStatusOptions = () => {
    return [
      {
        id: 'waiting_on_counterpart',
        title: __('waiting_on_counterpart')
      },
      {
        id: 'approved',
        title: __('approved')
      },
    ]
  }
