import React, { useState, useMemo } from 'react';
import Modal from 'components/Modal';
import Heading  from 'components/Heading';
import Dropdown from '@hubins/components/Dropdown';
import Button, { ButtonWrap } from '@hubins/components/Button';
import ErrorMessage from 'components/ErrorMessage';
import middleman from 'helpers/middleman';
import { __ } from 'localisation';

const AddToBatch = ({transactions, batches, open, setOpen, refetch, setTransactions}) => {

  const [selectedBatch, setSelectedBatch] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const options = useMemo(() => {
    if (!batches) return [];
    return batches.map((batch) => ({
      title: `Batch - ${batch.id}${batch.name ? ` - ${batch.name}` : ''}`,
      id: batch.uuid
    }));
  }, [batches]);

  const submit = async () => {
    setLoading(true);
    try {
      await middleman.promisePost(`/ql/crm/batches/update/${selectedBatch}`, {transactions})
      setOpen(false);
      refetch();
      setTransactions([]);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <Modal open={open} callback={setOpen}>
      <Dropdown
        options={options}
        value={selectedBatch}
        callback={setSelectedBatch}
        label="Choose Batch"
        placeholder='Select Batch'
      />
      <ButtonWrap>
        <Button
          className='cta'
          onClick={submit}
          disabled={!selectedBatch || loading}
          loading={loading}
          >
            Add to Batch
          </Button>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
      </ButtonWrap>
      <ErrorMessage text={error} showError={!!error} />
    </Modal>
  )

};

export default AddToBatch;