import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import useTabs from 'hooks/useTabs';
import FilterBatches from './FilterBatches';
import PendingBatches from './PendingBatches';
import UnhandledOrders from './UnhandledOrders';
import __ from 'localisation';

const tabs = [
  'tof',
  'investments',
  'batches',
];

const Batches = () => {

  const [index, setIndex] = useState(0);

  useTabs(setIndex, index, tabs);

  return (
    <div>
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={setIndex}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">{__('unhandled_orders')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('pending_batches')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('all_batches')}</Tab>
        </TabList>
        <TabPanel className="line-tabs__tab-panel">
          <UnhandledOrders />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <PendingBatches />
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <FilterBatches />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Batches;