import __ from 'localisation';
import React from 'react';
import LoadingBar from 'components/LoadingIndicators';

const rolesLoadingStateItem = {
  name: <LoadingBar small />,
  national_identity_number: <LoadingBar small />,
  action: <LoadingBar small />,
};

export const rolesLoadingState = [
  rolesLoadingStateItem,
  rolesLoadingStateItem,
  rolesLoadingStateItem,
];

export const rolesTableHeaders = [
  {
    Header: __('name'),
    accessor: 'name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: 'national_identity_number',
  },
  {
    Header: '',
    accessor: 'action',
    width: 30,
  }
];
