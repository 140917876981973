import React, { useState, useEffect } from 'react';
import Input from '@hubins/components/Input';
import InputCurrency from '@hubins/components/Input/Currency';
import Button, { ButtonWrap } from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import Dropdown from '@hubins/components/Dropdown';
import ErrorMessage from 'components/ErrorMessage';
import __ from 'localisation';
import { Column, Columns } from 'components/Columns';
import ApprovalEditTransactionSources from '@hubins/components/ApprovalEditTransactionSources';
import { appendUsedAmountToTransactionSources } from 'helpers/transactions';
import { getTransactionSourceOfFundsHeaders } from 'helpers/sof';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';

const TofApprovalComponent = ({ row: transaction, updateTransaction, subAccounts, risk, sourceOfFunds, reset, canEdit }) => {

  const [amount, setAmount] = useState(transaction.amount / 100);
  const [units, setUnits] = useState(transaction.units);
  const [origin, setOrigin] = useState(transaction.origin);
  const [originAccount, setOriginAccount] = useState(transaction.origin_account);
  const [loading, setLoading] = useState(false);
  const [subAccount, setSubAccount] = useState(transaction.sub_portfolio_uuid);
  const [status, setStatus] = useState(transaction.approval_status);
  const [error, setError] = useState(false);
  const [formattedSources, setFormattedSources] = useState([]);

  const sourceHeaders = getTransactionSourceOfFundsHeaders(transaction.sources || []);

  useEffect(() => {
    if (transaction && transaction.sources) {
      setFormattedSources(appendUsedAmountToTransactionSources(transaction.uuid, transaction.sources));
    }
  }, [transaction])

  const save = async (newStatus: string | undefined = undefined) => {
    setLoading(true);
    setError(false);
    try {
      if (newStatus === 'pending-approval') {
        if (!amount) {
          throw new Error(__('amount_required_err'));
        }
        if (!subAccount) {
          throw new Error(__('sub_account_required_err'));
        }
      }

      await middleman.promisePost(`/ql/crm/transaction/update/${transaction.uuid}`, {
        amount,
        units,
        origin,
        originAccount,
        subPortfolioUuid: subAccount,
        approvalStatus: newStatus
      })

      if (newStatus) {
        setStatus(newStatus)
      }

      updateTransaction(
        {
        ...transaction,
        amount,
        units,
        origin,
        origin_account: originAccount,
        sub_portfolio_uuid: subAccount,
        approval_status: newStatus || status
      }, )

    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  }

  const isApproved = status === 'approved' || status === 'pending-approval';

  return (
    <Columns className="neutral-1">
      <Column s="12" className="s-bottom-lg">
        <Heading size="4" className='s-bottom-lg s-top-md'>{__('used_sources_for_transaction')}</Heading>
        <SimpleTable
          headers={sourceHeaders}
          data={formattedSources}
        />
      </Column>
      <Column l="4" m="6">
        <InputCurrency
          label={__('choose_amount')}
          value={amount}
          // callback={setAmount}
          suffix='SEK'
          disabled={true}
        />
        <Input
          label={__('choose_units')}
          type='number'
          value={units}
          callback={setUnits}
          disabled={!canEdit}
        />
      </Column>
      <Column l="4" m="6">
      <Input
        label={__('choose_origin')}
        value={origin}
        callback={setOrigin}
        disabled={!canEdit}
      />

      <Input
        label={__('choose_origin_account')}
        value={originAccount}
        callback={setOriginAccount}
        disabled={!canEdit}
      />
      </Column>
      <Column l="4" m="6">
        <Dropdown
          label={__('choose_sub_account')}
          value={subAccount}
          placeholder={__('choose_sub_account')}
          options={subAccounts ? subAccounts.map((item) => ({ id: item.uuid, title: item.short_name })) : []}
          callback={setSubAccount}
          disabled={!canEdit}
        />
      </Column>
      <Column s="12">
      {canEdit && (
          <ButtonWrap>
            <Button
              className={isApproved ? 'danger' : 'success'}
              onClick={() => save(isApproved ? 'denied' : 'pending-approval')}
              loading={loading}
              disabled={loading}
            >
              {isApproved ? __('deny_transaction') : __('approve_transaction')}
            </Button>
            <Button
              onClick={() => save()}
              loading={loading}
              disabled={loading}
            >
              {__('save')}
            </Button>
            <ApprovalEditTransactionSources
              transaction={transaction}
              usedSources={transaction.sources}
              isHighRisk={risk && risk > 2}
              sourceOfFunds={sourceOfFunds}
              reset={reset}
            />
            <ErrorMessage text={error} showError={!!error} className='s-bottom-l' />
          </ButtonWrap>
      )}
      </Column>
    </Columns>
  );

};

export default TofApprovalComponent;