import React, { useState } from 'react';
import Box from 'components/Box';
import Main,  { MainTitle } from 'components/Main';
import Heading from 'components/Heading';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';

const ResetPassword = ({ title }) => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      email,
    };

    middleman.promisePost('/reset-password/store', body).then(() => {
      setLoading(false);
      setSuccess(true);
    }).catch(err => {
      console.error(err.message);
      setError(__('reset_password_error'));
      setLoading(false);
    });

  };
  if (success) {
    return (
      <Main center>
        <Box size="medium" bg="white" style={{ width: 480, maxWidth: "100%" }}>
          <Heading size="3">
            {__('reset_success')}
          </Heading>
          <p>
            {__('reset_success_text')}
          </p>
          <Button href="/">
            {__('to_login')}
          </Button>
        </Box>
      </Main>
    );
  }

  return (
    <>
      <MainTitle title={title} className="center" />
      <Main center>
        <Box size="medium" bg="white" style={{ width: 480, maxWidth: "100%" }}>
          <form onSubmit={submit}>
            <Input
              type="text"
              label={__('email')}
              value={email}
              callback={setEmail}
              className="s-md"
            />
            {error && (
              <p className='c-danger'>
                {error}
              </p>
            )}
            <Button
              className="spacing primary center"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {__('request_reset')}
            </Button>


          </form>
        </Box>
      </Main>
    </>
  );
};

export default ResetPassword;
