import React, { useState, useEffect } from 'react';
import { LoadingBox } from 'components/LoadingIndicators';
import Summary from './Summary';

import __ from 'localisation/hubins/registration';
import __ui from 'localisation';

import middleman from 'helpers/middleman';


const FormsSummary = ({ forms, afterLoading, caseId, withoutTitle, sourceId, type, portfolioUuid, profileUuid, editRoute, userId, endDate, language = undefined }) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState(false);

  const fetchForms = async () => {
    setLoading(true);
    const promises = forms.map((key) => {
      // TODO: remove /ql/crm endpoint
      // const endpoint = uid ? '/ql/crm/forms/summary' : '/forms/summary';
      const endpoint = '/forms/summary';
      return middleman.promisePost(endpoint, { key, caseId, sourceId, type, portfolioUuid, profileUuid, userId, endDate, language })
        .then(res => (res.data))
        .catch(() => {
          console.error('failed fetching: ', key);
          return false;
        });
    });

    const parsedSummaries = await Promise.all(promises).then(results => {
      return results.filter(item => item !== false);

    });
    setSummary(parsedSummaries);
    setLoading(false);
  };

  useEffect(() => {
    fetchForms();
  }, [profileUuid, portfolioUuid, userId, endDate]);

  useEffect(() => {
    if (!loading && afterLoading) {
      afterLoading();
    }

  }, [loading]);

  if (loading) {
    return <LoadingBox />;
  }

  if (summary === false) {
    return <>{__ui('something_went_wrong')}</>;
  }

  return (
    <>
      {summary.map((item, index) => {
        return (
          <Summary
            key={`summary-${index}`}
            content={item.content}
            response={item.response}
            title={withoutTitle ? '' : __(item.key)}
            editRoute={editRoute}
          />
        );
      })}
    </>
  );
};

FormsSummary.defaultProps = {
  withoutTitle: false
};

export default FormsSummary;
