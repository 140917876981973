import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal';
import { LoadingBox } from 'components/LoadingIndicators';
import middleman from 'helpers/middleman';
import Dropdown from "@hubins/components/Dropdown";
import Button, { ButtonWrap } from "@hubins/components/Button";
import Heading from 'components/Heading';

const AssignRole = ({ open, close, updateRoleWithActiveUser, roleName }) => {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchUsers = async () => {
    setLoading(true);
    const result = await middleman.get(`/ql/crm/role/users/${roleName}`);
    setUsers(result.data);
    setLoading(false);
  };

  useEffect(() => {
    if (open && !users) {
      fetchUsers();
    }
  }, [open, users]);

  const submit = () => {
    const theUser = users[selectedUser];
    updateRoleWithActiveUser(theUser);
    close();
  };


  return (
    <Modal open={open}>
      <Heading size="3" className="s-bottom-l">Assign this approval to a user</Heading>
      {loading ? <LoadingBox /> : (
        <>
          <Dropdown
            label="Select user"
            className="s-bottom-l"
            value={selectedUser}
            callback={setSelectedUser}
            placeholder="Select user"
            options={users.map((u, index) => ({ title: u.name, id: index }))}
          />
          <ButtonWrap>
            <Button onClick={submit} className='cta' disabled={!selectedUser && selectedUser !== 0}>
            Save
            </Button>
            <Button onClick={close} className='secondary'>
            Cancel
            </Button>
          </ButtonWrap>
        </>
      )}
    </Modal>
  );
};

export default AssignRole;
