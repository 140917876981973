import React, { Component } from 'react';
import __ from 'localisation';

class DocumentsEdit extends Component {

  componentDidMount() {
    this.props.callback(this.props.id, {
      type: 'documents',
      id: this.props.id,
      title: __('document'),
    });
  }

  render() {
    return (
      <div className="section-padding">
        <p>This will show all of the related documents for this security. So this component is only used for moving around this section on the page.</p>
      </div>
    );
  }

}

export default DocumentsEdit;
