import React, { useState, useMemo } from 'react';
import { Column, Columns } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import { useTofTransactions } from 'queries/transactions';
import { useBatches } from 'queries/batches';
import Heading from 'components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import __ from 'localisation';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import Checkbox from 'components/Checkbox';
import AddToBatch from '@hubins/components/AddToBatch';
import { defaultHeaders } from 'helpers/batches';

const UnhandledOrders = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [singleBatchLoading, setSingleBatchLoading] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([]);

  const { data, isLoading, refetch: refetchTransactions } = useTofTransactions('pending');
  const { data: batches, isLoading: isBatchesLoading, refetch: refetchBatches } = useBatches('TOF', 'pending');

  const handleCheckboxChange = (value, uuid) => {
    if (value) {
      setSelectedTransactions([...selectedTransactions, uuid]);
    } else {
      setSelectedTransactions(selectedTransactions.filter((transactionUuid) => transactionUuid !== uuid));
    }
  };

  const refetch = () => {
    refetchTransactions();
    refetchBatches();
  }

  const createSingleTransactionExecution = async (transaction: any) => {
    setSingleBatchLoading(true);
    try {
      await middleman.post('/ql/crm/batches/create', { type: 'TOF', transactions: [transaction.uuid] });
      refetch();
    } catch (e) {
      setError(e.message);
    }
    setSingleBatchLoading(false);
  }

  const batchTransactionHeaders = useMemo(() => {
    return [
      {
        Header: '',
        accessor: 'selected',
        maxWidth: 50,
        Cell: ({ row }) => (
          <Checkbox
            optionsState={{ value: row.original.selected }}
            callback={(value) => handleCheckboxChange(value, row.original.uuid)}
            disabled={row.original.batch_uuid}
          />
        )
      },
      {
        Header: '',
        accessor: 'single_transaction_execution',
        width: 200,
        Cell: ({ row }) => (
          <Button
            onClick={() => createSingleTransactionExecution(row.original)}
            disabled={singleBatchLoading}
            loading={singleBatchLoading}
            size="small"
          >
            {__('create_single_transaction_execution')}
          </Button>
        )
      },
      ...defaultHeaders
    ];
  }, [selectedTransactions, singleBatchLoading]);

  const transactions = useMemo(() => {
    return data?.filter((transaction: any) => (!transaction.batch_uuid))?.map((transaction: any) => ({
      ...transaction,
      selected: selectedTransactions.includes(transaction.uuid)
    }));
  }, [data, batches, selectedTransactions]);

  const createBatch = async () => {
    setLoading(true);
    try {
      await middleman.post('/ql/crm/batches/create', { type: 'TOF' });
      refetchBatches();
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <>
      <AddToBatch
        transactions={selectedTransactions}
        setTransactions={setSelectedTransactions}
        batches={batches}
        open={open}
        setOpen={setOpen}
        refetch={refetch}
      />
      <Columns>
        <Column s="12">
          <ButtonWrap>
            <Button onClick={createBatch} disabled={loading} loading={loading}>{__('create_batch')}</Button>
            <Button
              className='cta'
              onClick={() => setOpen(true)}
              disabled={selectedTransactions.length === 0}
              icon="add"
              iconColor="white"
            >
              {__('add_to_batch')}
            </Button>
          </ButtonWrap>
          <ErrorMessage text={error} showError={!!error} />
        </Column>
        <Column s="12">
          <Heading size={4} className='s-bottom-lg'>{__('unprocessed_orders')}</Heading>
          <SimpleTable headers={batchTransactionHeaders} data={transactions || []} />
        </Column>
      </Columns>
    </>
  )

};

export default UnhandledOrders;