import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import SimpleTable from 'modules/Tables/SimpleTable';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  batchUuid: string;
}

const headers = [
  {
    Header: 'Type',
    accessor: 'type'
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp'
  },
  {
    Header: 'Changes',
    accessor: 'changes'
  },
  {
    Header: 'User',
    accessor: 'user'
  }
];

const BatchHistoryModal = ({open, setOpen, batchUuid}: Props) => {

  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const fetchHistory = async () => {
    setLoading(true);
    try {
      const historyResponse = await middleman.get(`/ql/crm/batches/history/${batchUuid}`);
      setHistory(historyResponse.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (open) {
      fetchHistory();
    }
  }, [open])

  return (
    <Modal open={open} callback={setOpen}>
      {loading ? (
        <LoadingBox />
      ) : (
        <SimpleTable headers={headers} data={history} />
      )}
    </Modal>
  )

};

export default BatchHistoryModal;