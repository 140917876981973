import React, { Component } from 'react';
import TextEditor from 'components/TextEditor';
// import { EditorState, convertFromRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
import { Columns, Column } from 'components/Columns';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';

class CaseColumnsEditLoaded extends Component {


  constructor(props) {
    super(props);
    this.state = {
      type: 'textColumn',
      id: this.props.id,
      left: 'value' in this.props ?
        props.EditorState.createWithContent(props.convertFromRaw(this.props.value.left)) :
        props.EditorState.createEmpty(),
      right: 'value' in this.props ?
        props.EditorState.createWithContent(props.convertFromRaw(this.props.value.right)) :
        props.EditorState.createEmpty()
    };
    this.onEditorStateChangeLeft = this.onEditorStateChangeLeft.bind(this);
    this.onEditorStateChangeRight = this.onEditorStateChangeRight.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.state.id, {
      type: this.state.type,
      id: this.state.id,
      left: this.state.left,
      right: this.state.right
    });
  }

  onEditorStateChangeLeft(editorState) {
    const newValue = {
      type: 'textColumn',
      id: this.state.id,
      left: editorState,
      right: this.state.right
    };

    this.setState({
      left: editorState
    });

    this.props.callback(this.state.id, newValue);

  }

  onEditorStateChangeRight(editorState) {
    const newValue = {
      type: 'textColumn',
      id: this.state.id,
      left: this.state.left,
      right: editorState
    };

    this.setState({
      right: editorState
    });

    this.props.callback(this.state.id, newValue);
  }

  render() {
    return (
      <Columns>
        <Column xs="6">
          <TextEditor
            extended
            editorState={this.state.left}
            onEditorStateChange={this.onEditorStateChangeLeft}
          />
        </Column>
        <Column xs="6">
          <TextEditor
            extended
            editorState={this.state.right}
            onEditorStateChange={this.onEditorStateChangeRight}
          />
        </Column>
      </Columns>
    );
  }

}

const CaseColumnsEdit = Loadable.Map({
  loader: {
    EditorState: () => import('draft-js/lib/EditorState'),
    convertFromRaw: () => import('draft-js/lib/convertFromRawToDraftState'),
  },
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <CaseColumnsEditLoaded {...props}
      EditorState={loaded.EditorState.default}
      convertFromRaw={loaded.convertFromRaw.default}
    />;
  },
});


export default CaseColumnsEdit;
