import React, { useState } from 'react';
import { useComments } from 'queries/comments';
import LoadingBar from 'components/LoadingIndicators';
import Heading from 'components/Heading';
import __ from 'localisation';
import { Column, Columns } from 'components/Columns';
import NameInitialsBadge from '@hubins/components/NameInitialsBadge';
import Icon from '@hubins/components/IconNew';
import AddComment from './AddComment';
import Button from '@hubins/components/Button';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink';
import { connect } from 'react-redux';
import middleman from 'helpers/middleman';
import ReadMore from 'components/Accordeon/ReadMore';
import './index.scss';

type CommentProps = {
  comment: any,
  handleReplyToComment: (comment: any) => void;
  handleEditComment: (comment: any) => void;
  depth: number;
  userUuid: string;
  handleDeleteComment: (commentUuid: string) => void;
}

const Comment = ({ comment, handleReplyToComment, handleEditComment, depth, userUuid, handleDeleteComment }: CommentProps) => {
  return (
    <>
      <div className="comment-container white">
        <div className="comment">
            <div>
              <NameInitialsBadge name={comment.authorDisplayName} />
            </div>
            <div className='comment__content'>
              <p>
                <strong>{comment.authorDisplayName}</strong>
                <span className="body-small s-left-s c-neutral-5">{comment.createdAt}</span>
                {depth < 3 && (
                  <Button size="small" className="transparent s-left-s" onClick={() => handleReplyToComment(comment)}>
                    {__('reply')}
                  </Button>
                )}
                {userUuid === comment?.authorUuid && (
                  <>
                    <Button onlyIcon size="small" icon='pencil write 2' className="comment-edit-buttons" onClick={() => handleEditComment(comment)} />
                    <Button onlyIcon size="small" icon='Bin by Streamlinehq' className="comment-edit-buttons" onClick={() => handleDeleteComment(comment.uuid)} />
                  </>
                )}
                {comment.channel && (
                  <span className="body-small c-neutral-5">
                    <br />
                    {comment.channel.split(',').map((item: string) => {
                      return __(item)
                    }).join(', ')}
                  </span>
                )}
              </p>
              <p>{comment.commentBody}</p>
            </div>
        </div>

        {comment.files && comment.files.length > 0 && (
          <div className='comment__files'>
            {comment.files.map((file: any) => (
              <AuthDownloadLink
                className='transparent small'
                key={file.uuid}
                size="small"
                url={`/crm/comments/download/${file.uuid}`}
                icon="Office File Pdf 1 by Streamlinehq"
                filename={file.name}>
                  {file.name}
                </AuthDownloadLink>
            ))}
          </div>
          )}

      </div>
      {(comment.childComments && comment.childComments.length > 0) && (
        <>
          <div className='comment-reply'>
            <div className="comment-reply__seperator">
              <Icon icon="Arrow direction" size={24} />
            </div>
            <div className='comment-reply__comments'>
              {comment.childComments.map((childComment: any) => (
                <Comment
                  key={childComment.uuid}
                  comment={childComment}
                  handleReplyToComment={handleReplyToComment}
                  handleEditComment={handleEditComment}
                  depth={depth + 1}
                  userUuid={userUuid}
                  handleDeleteComment={handleDeleteComment}
                />
              ))}
            </div>
          </div>
        </>
      )}

    </>
  )
}

type CommentsProps = {
  model: string;
  modelUuid: string;
  userUuid: string;
  presetChannels?: string[];
}


const Comments = ({ model, modelUuid, userUuid, presetChannels }: CommentsProps) => {

  const [openModal, setOpenModal] = useState(false);
  const [replyToComment, setReplyToComment] = useState(null);
  const [editComment, setEditComment] = useState(null);

  const { data, isLoading, isFetched, refetch } = useComments(model, modelUuid);

  const closeModal = () => {
    setOpenModal(false);
    setReplyToComment(null);
    setEditComment(null);
  }

  const handleReplyToComment = (comment: any) => {
    setReplyToComment(comment);
    setOpenModal(true);
  }

  const handleEditComment = (comment: any) => {
    setEditComment(comment);
    setOpenModal(true);
  }

  const handleDeleteComment = async (commentUuid: string) => {
    try {
      if (confirm(__('are_you_sure_you_want_to_delete_this_comment'))) {
        await middleman.promiseDelete(`/crm/comments/${model}/${modelUuid}/${commentUuid}`);
        refetch();
      }
    } catch (e) {
      console.error(e);
    }
  }

  if (isLoading || !isFetched) {
    return <LoadingBar />
  }

  return (
    <>
      {openModal && (
        <AddComment
          closeModal={closeModal}
          refetch={refetch}
          replyToComment={replyToComment}
          model={model}
          modelUuid={modelUuid}
          editComment={editComment}
          presetChannels={presetChannels}
        />
      )}
      <div className='box-padding neutral-1'>
        <Heading size={4} className="s-bottom-xs">
          {__('comments')}
        </Heading>
        <Button onClick={() => setOpenModal(true)} className='s-bottom-xl'>
          {__('add_comment')}
        </Button>
      {data?.slice(0, 1).map((comment: any) => (
        <Comment
          key={comment.uuid}
          comment={comment}
          handleReplyToComment={handleReplyToComment}
          handleEditComment={handleEditComment}
          depth={0}
          userUuid={userUuid}
          handleDeleteComment={handleDeleteComment}
        />
        ))}
        {data?.length > 1 && (
          <ReadMore
            noBleed
            hiddenContent={data?.slice(1).map((comment: any) => (
              <Comment
                key={comment.uuid}
                comment={comment}
                handleReplyToComment={handleReplyToComment}
                handleEditComment={handleEditComment}
                depth={0}
                userUuid={userUuid}
                handleDeleteComment={handleDeleteComment}
              />
            ))}
          />
        )}
    </div>
    </>
  )
};

const mapStateToProps = (state: any) => ({
  userUuid: state.auth.User.uuid,
});

export default connect(mapStateToProps)(Comments);