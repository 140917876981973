import React, { useEffect, useState, useCallback } from 'react';
import middleman from 'helpers/middleman';
import Button from '@hubins/components/Button';
import { /*rolesTableHeaders,*/ rolesLoadingState } from 'helpers/roles';
import { Columns, Column } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';
import AddUserToProfileModal from './AddUserToProfileModal';
import __ from 'localisation';

const rolesTableHeaders = [
  {
    Header: __('name'),
    accessor: 'name',
  },
  {
    Header: __('ssn_org_num'),
    accessor: 'national_identity_number',
  },
  {
    Header: "",
    accessor: 'action',
  }
];


const ProfileRoles = ({ profileUuid }) => {
  const [signatories, setSignatories] = useState(rolesLoadingState);
  const [beneficialOwners, setBeneficialOwners] = useState(rolesLoadingState);
  const [
    investmentRepresentatives,
    setInvestmentRepresentatives
  ] = useState(rolesLoadingState);
  const [roleToAdd, setRoleToAdd] = useState('');
  const [loading, setLoading] = useState(false);



  const fetchProfileWithRoles = useCallback(async () => {

    setLoading(true);

    const deleteRoleForProfile = async (role, user) => {
      if (confirm(`Are you sure you want to remove ${user.name} as ${__(role)}?`)) {
        await middleman.removeUserProfileRole(profileUuid, user.uuid, role);
        window.location.reload();
      }
    };

    const deleteRoleButton = (role, profile) => {
      return <Button
        loading={loading}
        disabled={loading}
        onlyIcon
        className=''
        icon="Bin by Streamlinehq"
        onClick={() => deleteRoleForProfile(role, profile)} />;
    };

    try {
      const response = await middleman.get(`/profile/${profileUuid}`);
      const profile = response.data;
      const rawSignatories = profile.signatories || [];
      const rawBeneficialOwners = profile.beneficial_owners || [];
      const rawInvestmentRepresentatives = profile.investment_representatives || [];

      const signatoriesWithTableData = [
        ...rawSignatories.map((prof) => ({
          ...prof,
          action: deleteRoleButton('signatory', prof)
        }))
      ];



      const beneficialOwnersWithTableData = [
        ...rawBeneficialOwners.map((prof) => ({
          ...prof,
          action: deleteRoleButton('beneficial_owner', prof)
        }))
      ];

      const investmentRepresentativesWithTableData = [
        ...rawInvestmentRepresentatives.map((prof) => ({
          ...prof,
          action: deleteRoleButton('investment_representative', prof)
        }))
      ];

      setSignatories(signatoriesWithTableData);
      setBeneficialOwners(beneficialOwnersWithTableData);
      setInvestmentRepresentatives(investmentRepresentativesWithTableData);

    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  }, [
    profileUuid,
    setSignatories,
    setBeneficialOwners,
    setInvestmentRepresentatives,
    setLoading,
    setRoleToAdd,
    loading]);

  useEffect(() => {
    fetchProfileWithRoles();
  }, []);

  return (
    <Columns>
      <AddUserToProfileModal
        roleToAdd={roleToAdd}
        setRoleToAdd={setRoleToAdd}
        profileUuid={profileUuid}
      />
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Signatories
        </Heading>
        <SimpleTable
          // data={signatories}
          data={[
            {
              name: "Add new signatory",
              national_identity_number: "",
              action: <Button
                onClick={() => { setRoleToAdd('signatory'); }}
                className='transparent'
                icon="add"
                onlyIcon
              />
            }, ...signatories
          ]}
          headers={rolesTableHeaders}
        />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Beneficial owners
        </Heading>
        <SimpleTable
          headers={rolesTableHeaders}
          data={[
            {
              name: "Add new beneficial owner",
              national_identity_number: "",
              action: <Button
                onClick={() => { setRoleToAdd('beneficial_owner'); }}
                className='transparent'
                icon="add"
                onlyIcon
              />
            },
            ...beneficialOwners]}
        />
      </Column>
      <Column m="6">
        <Heading size="4" className="s-bottom-md">
          Investment representatives
        </Heading>
        <SimpleTable
          headers={rolesTableHeaders}
          data={[
            {
              name: "Add new investment representative",
              national_identity_number: "",
              action: <Button
                onClick={() => { setRoleToAdd('investment_representative'); }}
                className='transparent'
                icon="add"
                onlyIcon
              />
            },
            ...investmentRepresentatives
          ]}
        />
      </Column>
    </Columns>
  );

};

export default ProfileRoles;
