import React from 'react';
import Icon from '@hubins/components/IconNew';
import './index.scss';

const AccordeonButton = ({children, isExpanded, className, ...restProps}) => (
  <button className={`accordeon-button ${className}`} {...restProps}>
    <div>{children}</div>
    <Icon
      icon={isExpanded ? "arrow up 1" : "arrow down 1"}
      size={16}
      ariaHidden
    />
  </button>
)

export default AccordeonButton;