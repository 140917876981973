import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import './index.scss';
import Imgix from 'react-imgix';
import __ from 'localisation';
import Icon from '@hubins/components/IconNew';
import middleman from 'helpers/middleman';

class ImageUpload extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      url: this.props.value ? this.props.value : false,
      submitted: false,
      text: this.props.text ? this.props.text : "",
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentDidMount() {
    if ('callback' in this.props) {
      this.props.callback(this.props.id, {
        id: this.props.id,
        value: this.state.url,
        text: this.state.text
      }, this.props.index);
    }
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  onFormSubmit(file) {
    if (file.length === 0) {
      return;
    }
    this.setState({
      submitted: true
    });
    this.uploadFile(file).then((response) => {
      if (this.unmount) {
        return;
      }
      this.setState({
        url: response.data,
      });

      if ('callback' in this.props) {
        this.props.callback(this.props.id, {
          id: this.props.id,
          value: response.data,
          text: this.state.text
        }, this.props.index);
      }

    }).catch(error => {
      console.error(error);
      return;
    });
  }

  handleTextChange = (e) => {
    const text = e.target.value;
    this.setState({
      text
    });

    if ('callback' in this.props) {
      this.props.callback(this.props.id, {
        id: this.props.id,
        value: this.state.url,
        text
      }, this.props.index);
    }
  };

  remove() {
    this.setState({
      url: false,
      submitted: false
    });

    if ('callback' in this.props) {
      this.props.callback(this.props.id, {
        id: this.props.id,
        value: false
      }, this.props.index);
    }

  }

  uploadFile(file) {
    const url = `/ql/crm/formscms/file/store/${this.props.folder}`;
    const formData = new FormData();
    formData.append('file', file[0]);

    return middleman.postFormData(url, formData);
  }

  render() {
    if (!this.state.url || this.state.url === '/illustrations/investments_empty.svg') {
      return (
        <Fragment>
          {!this.state.submitted
            ? (

              <Dropzone
                onDrop={this.onFormSubmit}
                accept="image/jpeg, image/png, image/gif, image/bmp, image/webp"
                multiple={false}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div {...getRootProps()} className={`dropzone flex-center ${isDragActive ? 'dropzone--active' : ''}`}>
                    <input {...getInputProps()} />
                    {__('dropzone_text')}
                  </div>
                )}
              </Dropzone>

            )
            : (
              <div className="dropzone__loader">
                <Icon icon="loading" anim="spin" size={16} spacing="right" />
              </div>
            )}
          <label className="section-padding">
            <div className="label-wrapper">
              <div className="label">ALT-text:</div>
            </div>
            <input type="text" className="input" onChange={this.handleTextChange} value={this.state.text} />
          </label>
        </Fragment>
      );
    } else {
      return (
        <div className="dropzone__image">
          <div className="loading-bar dropzone__thumbnail__wrap">
            <Imgix
              src={this.state.url}
              className="dropzone__thumbnail"
              fit="clip"
              imgProps={{
                alt: 'Uploaded image preview',
              }}
            />
          </div>
          <button className="button small" onClick={this.remove} type="submit">
            {__('remove_file')}
          </button>
          <label className="section-padding">
            <div className="label-wrapper">
              <div className="label">ALT-text:</div>
            </div>
            <input type="text" className="input" defaultValue={this.state.defaultText} onChange={this.handleTextChange} value={this.state.text} />
          </label>
        </div>
      );
    }
  }
}



export default ImageUpload;
