import React, { useState } from 'react';
import Heading from 'components/Heading';
import Button from '@hubins/components/Button';
import { useCollapse } from 'react-collapsed';
import IconNew from '@hubins/components/IconNew';

import './index.scss';


const ExpandableSummaryTable = ({
  className = '',
  titleSize = 4,
  ...props
}) => {

  const [isExpanded, setExpanded] = useState(false);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className={`summary-table__wrap ${className} s-bottom-xl`}>
      <header className="summary-table__expandable-header s-bottom-md">
        <button className='summary-table__expand-button' {...getToggleProps({ onClick: handleClick })}><Heading element="h4" size={titleSize}>{props.heading || ''}</Heading> <IconNew icon={`arrow ${'down'} 1`} className={isExpanded ? 'summary-table__expand-button__expanded' : ''} size={16} /></button>
        {props.editLink && (
          <Button className='white' icon="pencil write 2" href={props.editLink}>
            Uppdatera svar
          </Button>
        )}

      </header>
      <ul className="summary-table" {...getCollapseProps()}>
        {props.children}
      </ul>
    </div>
  );
};

export default ExpandableSummaryTable;
