import React, { useState } from 'react';
import Modal from 'components/Modal';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import Input from '@hubins/components/Input';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import Button from '@hubins/components/Button';
// import { swedishSsn } from "components/Validation";
// import ErrorMessage from 'components/ErrorMessage';
import { LoadingBar } from 'components/LoadingIndicators';
import middleman from 'helpers/middleman';
import __ from 'localisation';

const loadingObj = {
  name: <LoadingBar small />,
  national_identity_number: <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const AddUserToProfileModal = ({ roleToAdd, setRoleToAdd, profileUuid }) => {

  const [loading, setLoading] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [searchValue, setSearchValue] = useState('');
  const [users, setUsers] = useState([]);

  const addUser = async (user) => {
    if (confirm(`Are u sure you want to add ${user.name} as ${__(roleToAdd)}?`)) {
      setLoading(true);
      try {
        await middleman.addUserProfileRole(profileUuid, user.uuid, roleToAdd);
        // await middleman.post(`/userprofileroles/${profileUuid}`, {
        //   userUuid: user.uuid,
        //   roleName: roleToAdd,
        // });
        setLoading(false);
        window.location.reload();
      } catch (e) {
        setLoading(false);
        console.error(e.message);
      }
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'National identity number',
      accessor: 'national_identity_number',
    },
    {
      Header: 'Add user',
      Cell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              addUser(row.original);
            }}
            className=""
            loading={loading}
            disabled={loading}
          >
            Add
          </Button>
        );
      }
    }
  ];

  const fetchPage = async (page, searchParam: string | boolean = false) => {
    setLoading(true);
    setSelectedPage(page - 1);
    const res = await middleman.get(`/ql/crm/user/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`);
    setAmountOfPages(res.data.last_page);
    setUsers(res.data.data);
    setLoading(false);
  };

  const handlePageFetch = (index) => {
    fetchPage(index + 1, searchValue);
  };

  return (
    <Modal
      open={!!roleToAdd}
      callback={setRoleToAdd}
      overlayClassName=""
      shouldCloseOnOverlayClick={false}
      contentLabel={`Add ${__(roleToAdd)} to profile`}
    >
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">Add existing user</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Add none existing user</Tab>
        </TabList>
        <TabPanel>
          <Input
            label="Search"
            value={searchValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          />
          <ApiPaginationTable
            data={users ? users : loadingState}
            headers={columns}
            fetchData={handlePageFetch}
            pageCount={amountOfPages}
            index={selectedPage}
          />
        </TabPanel>
        <TabPanel>
          <></>
        </TabPanel>
      </Tabs>
    </Modal>
  );
};

export default AddUserToProfileModal;
