import React, { useState, useCallback } from 'react';
import { Columns, Column } from 'components/Columns';
import FileUpload from 'components/FileUpload';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import ErrorMessage from 'components/ErrorMessage';
import MultipleDropdown from '@hubins/components/MultipleDropdown';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { useFormFiles } from '../../../../packages/queries/files';
import { LoadingBar } from 'components/LoadingIndicators';
import AuthDownloadLink from '@hubins/components/AuthDownloadLink/AuthDownloadLink';

const availableTags = [
  {
    id: 'terms',
    title: 'Terms & conditions'
  },
  {
    id: 'gdpr',
    title: 'GDPR'
  },
];

const headers = [
  {
    Header: '',
    accessor: 'download',
    width: 90,
    Cell: ({ row }) => (
      <>
        {row.original.download === 'loading'
          ? <LoadingBar small />
          : <AuthDownloadLink
            url={`/v1/file/terms/${row.original.uuid}/download`}
            filename={row.original.name}
          >
          Hämta
          </AuthDownloadLink>}

      </>
    )
  },
  {
    Header: 'Namn',
    accessor: 'name',
  },
  {
    Header: 'Taggar',
    accessor: 'tags',
    Cell: ({ row }) => row.original.tags.map((tag) => tag.name).toString(),
  },
];

const loadingObj = {
  download: 'loading',
  name: <LoadingBar small />,
  tags: []
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
];

const Files = () => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [filterTags, setFilterTags] = useState([]);

  const { data, isLoading, error: queryError, refetch } = useFormFiles(filterTags, page + 1);
  const handleFilesChange = (files) => {
    setSelectedFiles(files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filePromises = selectedFiles.map((item) => {
      const formData = new FormData();
      formData.append('file', item);
      formData.append('filename', item.name);
      formData.append('tags', tags.toString());
      formData.append('public_visible', false);
      return middleman.postFormData('/v1/file/terms', formData);
    });

    try {
      setError(false);
      await Promise.all(filePromises);
      setSelectedFiles([]);
      refetch();
    } catch (Err) {
      console.error('File upload error', Err.message);
      setError(true);
    }

  };

  const handlePageChange = useCallback((newPage) => {
    newPage = newPage;
    setPage(newPage);
  }, []);

  const handleTagsChange = useCallback((newTags) => {
    setTags(newTags);
  }, []);

  const handleFilterTagsChange = useCallback((newTags) => {
    setFilterTags(newTags);
  }, []);

  return (
    <Columns>
      <Column m="6">
        <MultipleDropdown
          label="Taggar för uppladdade filer"
          options={availableTags}
          placeholder='Välj taggar'
          callback={handleTagsChange}
        />
        <FileUpload controlled value={selectedFiles} callback={handleFilesChange} />
        <ErrorMessage text={"Något gick fel vid uppladdning av filer"} showError={!!error} />
        <Button className="s-top-lg" icon="Upload Bottom by Streamlinehq" onClick={handleSubmit}>Ladda upp filer</Button>
      </Column>
      <Column m="6">
        <MultipleDropdown
          label="Filtrera på taggar"
          options={availableTags}
          placeholder='Välj taggar'
          callback={handleFilterTagsChange}
        />
        <ApiPaginationTable
          headers={headers}
          data={isLoading ? loadingState : data.data}
          pageCount={data ? data.last_page : 0} // TODO: Fix this
          index={data ? data.current_page - 1 : 0}
          fetchData={handlePageChange}
        />
      </Column>
    </Columns>
  );
};

export default Files;
