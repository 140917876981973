import React, { useState, useEffect } from 'react';
import { useBatch } from 'queries/batches';
import SimpleTable from 'modules/Tables/SimpleTable';
import Heading from 'components/Heading';
import { defaultHeadersWithRemoveAndEdit, hubinsStatusOptions, getBatchStatus, getBatchStatusColor } from 'helpers/batches';
import Button, { ButtonWrap } from '@hubins/components/Button';
import EditBatchTofTransaction from '@hubins/components/EditBatchTofTransaction';
import Dropdown from '@hubins/components/Dropdown';
import __ from 'localisation';
import middleman from 'helpers/middleman';
import { connect } from 'react-redux';
import ErrorMessage from 'components/ErrorMessage';
import LoadingBar from 'components/LoadingIndicators';
import Input from '@hubins/components/Input';
import { LoadingBox } from 'components/LoadingIndicators';
import FileUpload from 'components/FileUpload';
import FilesTable from '@hubins/components/FilesTable';
import BatchHistoryModal from '@hubins/components/BatchHistoryModal';
import Comments from '@hubins/components/Comments';
import { useHistory } from 'react-router-dom';

const Batch = ({ match, userRoles }) => {
  const history = useHistory();
  const { batchId } = match.params;
  const { data, isLoading, refetch } = useBatch(batchId);
  const [hubinsStatus, setHubinsStatus ] = useState('');
  const [quantumStatus, setQuantumStatus ] = useState('');
  const [editTransaction, setEditTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const [name, setName] = useState<any>('');
  const [editName, setEditName] = useState(false);
  const [files, setFiles] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [readyForExecution, setReadyForExecution] = useState(false);
  const [batchStatus, setBatchStatus] = useState('');

  const canEditHubins = userRoles.includes('hubins_backoffice');
  const canEditQuantum = userRoles.includes('quantum_backoffice');



  useEffect(() => {
    if (data && !initiated) {
      setHubinsStatus(data.hubins_status);
      setQuantumStatus(data.quantum_status);
      setName(data.name);
      setInitiated(true);
    }

    if (data) {
      setBatchStatus(data.status);
      setReadyForExecution(
        data.transactions.every(transaction => transaction.status === 'ready-for-execution' || transaction.status === 'completed' || transaction.status === 'premium_withdrawal_completed')
      );
    }

  }, [data, initiated]);

  const save = async (status = '') => {
    // add confirmation popup for when status is approved
    if (status === 'approved') {
      const confirmed = window.confirm(__('are_you_sure_you_want_to_complete_the_batch'));
      if (!confirmed) {
        return;
      }
    }
    setLoading(true);
    setError(null);
    try {

      if (status === 'approved' && (!canEditQuantum || hubinsStatus !== 'approved' || !readyForExecution)) {
        setError(__('batch_is_not_ready_for_execution'));
        setLoading(false);
        return;
      }

      await middleman.promisePost(`/ql/crm/batches/update/${data.uuid}`, {
        status: status || undefined,
        // quantum_status: canEditQuantum ? quantumStatus : undefined,
        hubins_status: (canEditHubins || canEditQuantum) ? hubinsStatus : undefined,
        name: name || undefined,
      });
      if (files.length > 0) {
        const filePromises = files.map(async (file) => {
          const formData = new FormData();
          formData.append('file', file);
          return middleman.post(`/ql/crm/batches/upload/${data.uuid}`, formData);
        });
        await Promise.all(filePromises);
        setFiles([]);
      }
      setEditName(false);
      refetch();
    } catch (e) {
      setError(e?.response?.data?.error || e.message);
    }
    setLoading(false);
  }

  const back = () => {
    history.goBack();
  }

  return (
    <>
      <Button className='cta s-bottom-xl' icon="arrow left 1" iconColor='white' iconLeft onClick={back}>
        {__('back')}
      </Button>
      <div className='s-bottom-xl'>
        {editName ? (
          <Input
            label={__('name')}
            value={name}
            callback={setName}
            className='input-max-width'
          />
        ) : (
          <Heading size={2}>{name || `${__('order_process')} ${data?.id || ''}`} <Button onlyIcon icon='pencil write 2' onClick={() => setEditName(!editName)} /></Heading>
        )}
        <Button
          onClick={() => setShowHistory(true)}
          icon="Common File Stack by Streamlinehq"
          className='s-bottom-l'
          iconLeft
        >
          History
        </Button>
        <Heading size={4}>
          Status: <span className={getBatchStatusColor(data?.status)}>{getBatchStatus(data)}</span>
        </Heading>
      </div>
      <Heading
        size={4}
        className='s-bottom-m'
      >
        Transactions
      </Heading>

      <SimpleTable headers={defaultHeadersWithRemoveAndEdit(refetch, setEditTransaction)} data={data?.transactions || []} />
      <EditBatchTofTransaction
        transaction={editTransaction}
        setTransaction={setEditTransaction}
        refetch={refetch}
        canEdit={canEditHubins}
      />
      <div className='s-top-xl'>
        {!initiated ? (
          <LoadingBox />
        ) : (
          <>
            <BatchHistoryModal open={showHistory} setOpen={setShowHistory} batchUuid={data.uuid} />
          </>
        )}
      </div>
      <Heading size={4}>Files</Heading>
      <FileUpload
        controlled
        value={files}
        callback={setFiles}
      />
      {initiated && (
        <FilesTable files={data?.files || []} downloadUrl={`/ql/crm/batches/download/${data.uuid}`} />
      )}
      <Comments model='Batch' modelUuid={data?.uuid} presetChannels={['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']} />
      {(canEditHubins || canEditQuantum) && (
        <div className='s-top-xl'>
          {(isLoading || !initiated) ? (
            <>
              <LoadingBar large />
            </>
          ) : (
            <>
              <Dropdown
                label={`${__('choose_status')}`}
                className='input-max-width'
                placeholder={__('choose_status')}
                options={hubinsStatusOptions()}
                value={hubinsStatus}
                callback={setHubinsStatus}
                disabled={batchStatus === 'approved'}
              />
            </>
          )}
        </div>
      )}

      <ButtonWrap>
        {/* <Button
          className='success large'
          disabled={((!canEditHubins && !canEditQuantum) || hubinsStatus !== 'approved' || quantumStatus !== 'approved' || !readyForExecution || loading)}
          loading={loading}
          onClick={() => save('approved')}
        >
          {__('complete_batch')}
        </Button> */}
        <Button
          className='success large'
          onClick={() => save('approved')}
          loading={loading}
          disabled={!canEditQuantum || hubinsStatus !== 'approved' || !readyForExecution || loading || batchStatus === 'approved'}
        >
          {__('complete_batch')}
        </Button>
        <Button
          className="cta large"
          onClick={() => save()}
          loading={loading}
          disabled={(!canEditHubins && !canEditQuantum) || loading}
        >
          {__('save')}
        </Button>
        <Button
          className='large'
          onClick={back}
        >
          {__('cancel')}
        </Button>
      </ButtonWrap>
      <ErrorMessage text={error} showError={!!error} />
    </>
  )
};

const mapStateToProps = (state) => ({
  userRoles: state.auth.User.userRoles,
});

export default connect(mapStateToProps)(Batch);