import React, { Component } from 'react';

class CaseVideoEdit extends Component {

  constructor(props){
    super(props);
    this.state = {
      value: 'value' in this.props ? this.props.value.value : '',
      type: 'caseVideo',
      id: this.props.id
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    this.props.callback(this.state.id, {
      value: this.state.value,
      type: this.state.type,
      id: this.state.id
    });
  }

  handleChange(e){
    this.setState({
      value: e.target.value
    });
    this.props.callback(this.state.id, {
      type: this.state.type,
      id: this.state.id,
      value: e.target.value
    });
  }

  render() {
    return (
      <input className="input" placeholder="Vimeo URL" onChange={this.handleChange} value={this.state.value} />
    );
  }

}

export default CaseVideoEdit;
