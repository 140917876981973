import React from 'react';
import Button from '@hubins/components/Button';
import Heading from 'components/Heading';

const EditComponent = ({ addComponent, name, content, image }) => {
  return (
    <Button
      className="medium-choice spacing flex-grow"
      onClick={(e) => {
        e.preventDefault();
        const id = name + Math.random();
        addComponent(
          {
            id: id,
            content:
                <>
                  <Heading size="5">{name}</Heading>
                  {React.cloneElement(content, { id })}
                </>
          }
        );
      }}>
      <img src={image} className="icon icon--large icon--noheight" alt={`Icon ${name}`} />
      <span>{name}</span>
    </Button>
  );

};

export default EditComponent;
