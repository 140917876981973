import React, { useEffect, useState } from 'react';
import RenderSections from './RenderSections';
import Heading from 'components/Heading';
import SectionForm from './SectionForm';

const Section = ({
  params,
  type,
  form,
  profileUuid,
  portfolioUuid,
  uuid,
  role,
  setSubmittedDueDiligence,
  submittedDueDiligence,
  isExecuted,
  hasBeenApproved,
  setInvalid,
  endDate,
}) => {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (form) {
      const newForms = form.split(',');
      setForms(newForms);
    }
  }, [form]);

  const SectionContent = RenderSections[type] ? RenderSections[type](params, role, isExecuted, setInvalid, endDate) : <Heading size="2">No content found for section with type {type}</Heading>;

  return (
    <>
      {SectionContent}
      <Heading size="3" className="s-top-xl">
        Due Diligence Checks
      </Heading>
      {forms && (
        forms.map((formKey, index) => (
          <SectionForm
            key={index}
            form={formKey}
            profileUuid={profileUuid}
            portfolioUuid={portfolioUuid}
            uuid={uuid}
            role={role}
            setSubmittedDueDiligence={setSubmittedDueDiligence}
            submittedDueDiligence={submittedDueDiligence}
            isExecuted={isExecuted}
            hasBeenApproved={hasBeenApproved}
          />
        ))
      )}
    </>
  );

};

export default Section;
