import React, { useState, useEffect } from 'react';
import useTabs from 'hooks/useTabs';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import __ from 'localisation';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';
import { LoadingBox } from 'components/LoadingIndicators';
import Icon from '@hubins/components/IconNew';
import Portfolios from './Portfolios';
import Registration from './Registration';
import AptitudeTests from 'views/Client/AptitudeTests';

const tabs = [
  'portfolios',
  'registration',
  'aptitude_tests'
]

const Profile = ({ match, location }) => {
  const [index, setIndex] = useState(0);
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true)

  const { profileUuid } = match.params;

  const fetchProfile = async () => {
    try {
      const res = await middleman.get(`/profile/${profileUuid}`);
      setProfile(res.data);
      setLoading(false);
    } catch (e) {
      console.error('error fetching profile', e);
    }
  }

  useEffect(() => {
    fetchProfile();
  }, [])

  useTabs(setIndex, index, tabs)

  if (loading) {
    return <LoadingBox />
  }

  return (
    <>
      <Heading size="2">
        {profile.name}
      </Heading>
      <div className="flex flex--row align-items-center s-top-lg">

        <Icon title="name" icon="user" size={16} className="s-right-s" />
        {profile.name}
        {/* <Button external small className="link withicon withicon--left" href={`tel:${phone}`}>
                    <Icon title="phone" icon="phone" size="small" />
                    {phone}
                  </Button> */}

        <Icon title="ssn" icon="Touch Id 1" size={16} className="s-right-s s-left-m" />
        {profile.national_identity_number}

        {/* <Button external small className="link withicon withicon--left" href={`mailto:${email}`}>
                    <Icon title="email" icon="mail" size="small" />
                    {email}
                  </Button> */}
      </div>
      <hr />
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
        onSelect={setIndex}
        selectedIndex={index}
      >
        <TabList className="line-tabs__tab-list">
          <Tab className="heading heading--5 line-tabs__tab">{__('menu_portfolios')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('registration')}</Tab>
          <Tab className="heading heading--5 line-tabs__tab">{__('aptitude_tests')}</Tab>
        </TabList>
        <TabPanel className="line-tabs__tab-panel">
          <div className="line-tabs__tab-content">
            <Portfolios profileUuid={profileUuid} />
          </div>
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <div className="line-tabs__tab-content">
            <Registration
              profileUuid={profileUuid}
              nationalIdentityNumber={profile.national_identity_number}
              isCompany={profile.type === 'COMPANY'}
              profile={profile}
            />
          </div>
        </TabPanel>
        <TabPanel className="line-tabs__tab-panel">
          <div className="line-tabs__tab-content">
            <AptitudeTests client={profile} />
          </div>
        </TabPanel>
      </Tabs>
    </>
  )

};

export default Profile;