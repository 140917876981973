import React, { useMemo, useState } from 'react';
import ExpandedRow from './ExpandedRow';
// Import React Table
import { useTable, useResizeColumns, useFlexLayout } from 'react-table';
import Button from '@hubins/components/Button';
import './index.scss';

const MAX_ITEMS_PER_PAGE = 10;
const EMPTY_PAGE_SIZE = 3;


const ExpandableTable = (props) => {

  const [expandedRow, setExpandedRow] = useState(null);

  let pageSize = EMPTY_PAGE_SIZE;
  if (props.showPagination && props.data.length >= MAX_ITEMS_PER_PAGE) {
    pageSize = MAX_ITEMS_PER_PAGE;
  } else if (props.data.length < MAX_ITEMS_PER_PAGE) {
    pageSize = props.data.length;
  }

  const columns = useMemo(() => [{Header: '', accessor: 'expander', Cell: ({row}) => {
    return (
      <Button
        onClick={() => setExpandedRow(expandedRow === row.index ? null : row.index)}
        icon={
          expandedRow === row.index ? "arrow up 1" : "arrow down 1"
        }
        iconColor="primary"
        onlyIcon={true}
        className="white"
      />
    );
  }}, ...props.headers], [props.headers, expandedRow]);
  const data = useMemo(() => props.data.map((row => {
    return {
      ...row,
      expander: '',
    };
  })), [props.data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable({
    columns,
    data,
  },
  useResizeColumns,
  useFlexLayout);

  return (
    <div className='table__wrapper'>
      <table {...getTableProps} className="table">
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, subIndex) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const isExpanded = expandedRow === index;
            const className = row.original.className || '';
            return (
              <>
              <tr {...row.getRowProps()} className={className}>

                {row.cells.map((cell, subIndex) => {
                  return (
                    <td {...cell.getCellProps()} className="flex-horizontal-center">{cell.render('Cell')}</td>
                  );
                })}
              </tr>
              <ExpandedRow
                row={row.original}
                component={props.component}
                colSpan={columns.length + 1}
                isExpanded={isExpanded}
              />
              </>
            );
          })}
        </tbody>
        {(props.footer) && (
          <tfoot>
            {footerGroups.map((footerGroup, index) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column, subIndex) => (
                  <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );

};

export default ExpandableTable;
