import React, { useEffect, useState } from 'react';
import { useTofTransactions } from 'queries/transactions';
import SimpleTable from 'modules/Tables/SimpleTable';
import { deleteHeader, loadingObj } from 'helpers/transactions';
import Heading from 'components/Heading';
import { __ } from 'localisation';
import Button from '@hubins/components/Button';
import { getMoney } from 'helpers';

const loadingState = [
  loadingObj,
  loadingObj,
];

type Step1Props = {
  portfolioUuid: string | undefined;
  setSelectedTransaction: (transaction: object) => void;
  setCreateNew: (createNew: boolean) => void;
}

const Step1 = ({ portfolioUuid, setSelectedTransaction, setCreateNew}: Step1Props ) => {

  const [formattedTransactions, setFormattedTransactions] = useState<object[]>([]);
  const { data: transactions, isLoading, isFetched, refetch } = useTofTransactions('draft', undefined, portfolioUuid ? [portfolioUuid] : undefined);

  const headers = [
    {
      Header: '',
      accessor: 'open',
    },
    deleteHeader(refetch),
    {
      Header: __('policy_holder'),
      accessor: 'portfolio.policy_holder',
      Cell: ({ value }) => value && Array.isArray(value) ? value.map((item) => item.name).join(', ') : value
    },
    {
      Header: __('portfolio'),
      accessor: 'portfolio.short_name'
    },
    {
      Header: __('initial_trade_amount'),
      accessor: 'amount',
      Cell: ({ value }) => value ? getMoney(value / 100) : ''
    },
    {
      Header: __('security'),
      accessor: 'security.name',
    },
    {
      Header: __('units'),
      accessor: 'units',
    },
    {
      Header: __('trade_origin'),
      accessor: 'origin',
    },
    {
      Header: __('trade_origin_account'),
      accessor: 'origin_account'
    },
    {
      Header: __('status'),
      accessor: 'status',
    },
  ];

  useEffect(() => {
    if (transactions) {
      setFormattedTransactions(transactions.map((transaction: object) => ({
        ...transaction,
        open: (
          <Button onClick={() => setSelectedTransaction(transaction)}>{__('continue')}</Button>
        ),
      })))
    }
  }, [transactions])

  return (
    <div>
      <Button onClick={() => setCreateNew(true)} className='s-top-xl' icon="add">
        {__('create_new_tof')}
      </Button>
      <Heading size={4} className='s-top-xl s-bottom-m'>{__('draft_transactions')}</Heading>
      <SimpleTable headers={headers} data={isLoading || !isFetched ? loadingState : formattedTransactions} />
    </div>
  )
};

export default Step1;