

export const isBackofficeUser = (userRoles) => {
  const needles = [
    'hubins_backoffice',
    'hubins_compliance',
    'quantum_backoffice',
    'quantum_compliance'
  ];
  return needles.some(needle => userRoles.includes(needle));
};

export const getBackofficeRole = (roles) => roles.find(role => ['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance'].includes(role));
