import React, { useEffect, useState, useCallback } from 'react';
import middleman from 'helpers/middleman';
import { LoadingBox } from 'components/LoadingIndicators';
import ConnectionsComponent from 'components/Connections';

const Connections = ({ portfolioUuid }) => {

  const [connections, setConnections] = useState(false);
  const [loading, setLoading] = useState(true);

  const getConnection = useCallback(async () => {
    const res = await middleman.promiseGet(`/ql/crm/portfolio/${portfolioUuid}/connections`);
    setConnections(res.data);
    setLoading(false);
  }, [portfolioUuid, setConnections]);

  useEffect(() => {
    getConnection();
  }, []);

  if (loading) return (<LoadingBox />);

  return (
    <>
      {connections && (
        <>
          <ConnectionsComponent connections={connections} />
        </>
      )}
    </>
  );



};

export default Connections;
