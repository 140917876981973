import React, {useRef, useState} from 'react';
import Button from '@hubins/components/Button';
import CreateForm from 'modules/CreateForm'
import Modal from "components/Modal";
import { appendAllFieldValues } from "helpers/formscms";

const FormModal = ({content, response, callback, buttonText = "Edit"}) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const formattedContent = useRef(appendAllFieldValues(content, response));

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
  }

  const handleSubmit = async (values) => {
    if (callback) {
      setError(null);
      setLoading(true);
      try {
        await callback(values);
        setOpen(false);
      } catch (e) {
        setError(`Submit error: ${e.message}`);
      }
      setLoading(false);
    }
  }

  return (
    <>
    <Button
      onClick={handleClick}
      className='medium'
      icon="Common File Text by Streamlinehq"
      >
      {buttonText}
    </Button>
    <Modal
      callback={closeModal}
      shouldCloseOnOverlayClick
      closeButton
      open={open}
    >
      <CreateForm
        formData={formattedContent.current}
        submitType={handleSubmit}
        loading={loading}
        disabled={loading}
        submitText="Save"
        secondaryButton
        secondarySubmitType={() => {setOpen(false)}}
        secondaryText="Close"
        error={error}
      />
    </Modal>
    </>
  );

};

export default FormModal;