import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Draggable } from 'react-beautiful-dnd';

import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button/';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';

import './index.scss';

export class SlideItem extends Component {
  render() {
    const { removeCallback, index, status } = this.props;

    return (
      <Button onClick={() => removeCallback(index, 'slide')} onlyIcon className="small sortable-slide">
        {status === 'slide' ?
          <Icon icon="remove" color="primary" /> :
          <Icon icon="Bin by Streamlinehq" color="primary" />
        }
      </Button>
    );
  }
}

export class DeleteItem extends Component {
  render() {
    const { removeCallback, index, status } = this.props;

    if (status !== 'slide') return false;

    return (
      <Button
        onClick={() => removeCallback(index, 'delete')}
        className="small withicon withicon--left sortable-delete">
        Confirm delete
        <Icon icon="Sign Warning" color="primary" size={16} />
      </Button>
    );

  }
}

class ItemLoaded extends Component {
  render() {
    const { status, item, index, removeCallback, Draggable } = this.props;
    return (
      <Draggable draggableId={item.id} index={index}>
        {(provided) => (
          <div
            className="sortable-list__itemwrap"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className={`sortable-list__item ${status ? 'sortable-list__item--' + status : ''}`}>
              <span className="sortable-handle"
                {...provided.dragHandleProps}
              >
                <Icon icon="navigation-menu" size={16} color="primary" label="Sort handle" />
              </span>

              <div className="sortable-list__content">
                {item.content}
              </div>
              <SlideItem removeCallback={removeCallback} index={index} status={status} />
            </div>

            <DeleteItem removeCallback={removeCallback} index={index} status={status} />
          </div>
        )}
      </Draggable>
    );
  }
}

const Item = Loadable({
  loader: () => import('react-beautiful-dnd' /* webpackChunkName: "react-beautiful-dnd" */),
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <ItemLoaded {...props}
      Draggable={loaded.Draggable}
    />;
  },
});

Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Item.defaultProps = {
  item: {},
  index: 0,
};

export default Item;
