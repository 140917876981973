import React, { Component, Fragment } from 'react';
import { Column, Columns } from 'components/Columns';
import Button from '@hubins/components/Button';
import { __ } from 'localisation';

class CaseStatsTableEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: 'value' in this.props ?
        this.props.value
        : {
          type: 'stats-table',
          id: this.props.id,
          leftHeader: '',
          rightHeader: '',
          left: [
            {
              label: '',
              value: ''
            }
          ],
          right: [
            {
              label: '',
              value: ''
            }
          ]
        }
    };
    this.removeRow = this.removeRow.bind(this);
    this.addRow = this.addRow.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.props.id, this.state.rows);
  }


  handleChange(value, index, rowName, type) {
    const newState = this.state.rows;
    newState[rowName][index][type] = value;

    this.setState({
      rows: newState
    });

    this.props.callback(this.props.id, newState);

  }

  handleTitleChange(value, name) {
    let newState = this.state.rows;
    newState[name] = value;

    this.setState({
      rows: newState
    });
  }

  addRow(name) {
    let stateRows = this.state.rows;
    let newRow = { label: '', value: '' };
    stateRows[name].push(newRow);

    this.setState({
      rows: stateRows
    });
  }

  removeRow(name) {
    let stateRows = this.state.rows;
    stateRows[name].pop();

    this.setState({
      rows: stateRows
    });
  }

  renderRows(name) {
    return this.state.rows[name].map((row, index) => (
      <Columns key={index} className={index % 2 === 0 ? '' : 'neutral-1'}>
        <Column s="12" m="6">
          <label>
            <div className="label">Label</div>
            <input
              className="input"
              onChange={(e) => { this.handleChange(e.target.value, index, name, 'label'); }}
              value={this.state.rows[name][index].label} />
          </label>
        </Column>
        <Column s="12" m="6">
          <label>
            <div className="label">Value</div>
            <input
              className="input"
              onChange={(e) => { this.handleChange(e.target.value, index, name, 'value'); }}
              value={this.state.rows[name][index].value} />
          </label>
        </Column>
      </Columns>
    ));
  }



  render() {
    return (
      <Fragment>
        <Columns className="border-bottom">
          <Column xs="6">
            <label>
              <div className="label">Left header</div>
              <input className="input" value={this.state.rows.leftHeader} onChange={(e) => { this.handleTitleChange(e.target.value, 'leftHeader'); }} />
            </label>
          </Column>
          <Column xs="6">
            <label>
              <div className="label">Right header</div>
              <input className="input" value={this.state.rows.rightHeader} onChange={(e) => { this.handleTitleChange(e.target.value, 'rightHeader'); }} />
            </label>
          </Column>
        </Columns>
        <Columns>
          <Column xs="6">{this.renderRows('left')}</Column>
          <Column xs="6">{this.renderRows('right')}</Column>
        </Columns>
        <Columns>
          <Column className="center" xs="6">
            <Button className="s-right" onClick={() => { this.addRow('left'); }}>{__('add_row')}</Button>
            <Button className="s-left" onClick={() => { this.removeRow('left'); }}>{__('remove_row')}</Button>
          </Column>
          <Column className="center" xs="6">
            <Button className="s-right" onClick={() => { this.addRow('right'); }}>{__('add_row')}</Button>
            <Button className="s-left" onClick={() => { this.removeRow('right'); }}>{__('remove_row')}</Button>
          </Column>
        </Columns>
      </Fragment>
    );
  }

}

export default CaseStatsTableEdit;
