import React from 'react';
import Sof from './sections/Sof';
import Deposit from './sections/Deposit';
import Portfolio from './sections/Portfolio';
import Tof from './sections/Tof';

const sof = (props, role, isExecuted, setInvalid, endDate) => {
  return (
    <Sof
      {...props}
      canEdit={role.can_edit}
      isExecuted={isExecuted}
      setInvalid={setInvalid}
      endDate={endDate}
    />
  );
};

const deposit = (props, role, isExecuted, setInvalid, endDate) => {
  return (
    <Deposit
      {...props}
      canEdit={role.can_edit}
      isExecuted={isExecuted}
      setInvalid={setInvalid}
      endDate={endDate}
    />
  );
};

const portfolio = (props, role, isExecuted, setInvalid, endDate) => {
  return (
    <Portfolio
      {...props}
      canEdit={role.can_edit}
      isExecuted={isExecuted}
      setInvalid={setInvalid}
      endDate={endDate}
    />
  );
};

const tof = (props, role, isExecuted, setInvalid, endDate) => {
  return (
    <Tof
      {...props}
      canEdit={role.can_edit}
      isExecuted={isExecuted}
      setInvalid={setInvalid}
      endDate={endDate}
    />
  );
};

export default { sof, deposit, portfolio, tof };
