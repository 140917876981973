import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import Dropdown from '@hubins/components/Dropdown';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';

const AddTagModal = ({ open, setOpen, profileUuid, portfolioUuid }) => {

  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState('');
  const [target, setTarget] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchTags = async () => {
    const response = await middleman.get('/ql/crm/tags');
    setTags(response.data);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const saveTag = async () => {
    setLoading(true);
    try {
      const url = target === 'profile' ? `/ql/crm/profile/tag/${profileUuid}` : `/ql/crm/portfolio/tag/${portfolioUuid}`;
      await middleman.promisePost(url, { tagId: selected });
      setOpen(false);
    } catch (e) {
      console.error(e.message);
    }
    setOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      callback={setOpen}
      closeButton
      shouldCloseOnOverlayClick
    >
      {/* <Wrapper> */}
      <div className='flex'>
        <Heading size="2" className="s-bottom-l">Add tag</Heading>
        <Dropdown
          label="Select tag"
          options={tags.map(tag => ({ title: tag.name, id: tag.id }))}
          callback={async (value, id) => {
            setSelected(value);
          }}
          value={selected}
          placeholder="Select tag"
        />
        <Dropdown
          label="Select target"
          options={[
            { title: 'Profile', id: 'profile' },
            { title: 'Portfolio', id: 'portfolio' },
          ]}
          callback={async (value) => {
            setTarget(value);
          }}
          value={target}
          placeholder="Select target"
        />
        <ButtonWrap>
          <Button className='cta' onClick={saveTag} loading={loading}>Save Tag</Button>
        </ButtonWrap>
      </div>
      {/* </Wrapper> */}
    </Modal>
  );
};

export default AddTagModal;
