import React, { useEffect, useState, useCallback } from "react";
import Button from "@hubins/components/Button";
import Modal from "components/Modal";
import middleman from "@hubins/middleman";
import { __ } from "localisation";
import { UserData } from "./User.types";

type Role = {
    id: number;
    name: string;
}

interface AddRoleModalProps {
    user: UserData | null;
    open: boolean;
    setOpen: (open: boolean) => void;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({ user, open, setOpen }) => {
    const [allRoles, setAllRoles] = useState<Role[]>([]);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const fetchAllRoles = useCallback(async () => {
        setLoading(true);
        try {
            const res = await middleman.getUserRoles();
            setAllRoles(res);
        } catch (error) {
            console.error("Error fetching all roles:", error);
            const message = error.error || "Error fetching all roles. Please try again.";
            setResponseMessage(message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchUserRoles = useCallback(async () => {
        if (!user) return;
        setLoading(true);
        try {
            const res = await middleman.getRolesForUser(user.uuid);
            setUserRoles(res.map((role: Role) => role.name));
        } catch (error) {
            console.error("Error fetching user roles:", error);
            const message = error.response.data.error || "Error fetching user roles. Please try again.";
            setResponseMessage(message);
        } finally {
            setLoading(false);
        }
    }, [user]);

    const handleRoleChange = useCallback(async (roleName: string, action: 'add' | 'remove') => {
        if (!user) return;
        setLoading(true);
        setResponseMessage("");
        try {
            if (action === 'add') {
                await middleman.addUserRole(user.uuid, roleName);
            } else {
                await middleman.removeUserRole(user.uuid, roleName);
            }
            await fetchUserRoles();
            setResponseMessage(`Role successfully ${action === 'add' ? 'added' : 'removed'}.`);
        } catch (error) {
            const message = error.response.data.error || `Error ${action === 'add' ? 'adding' : 'removing'} role. Please try again.`;
            setResponseMessage(message);
        } finally {
            setLoading(false);
        }
    }, [user, fetchUserRoles]);

    useEffect(() => {
        if (open) {
            fetchAllRoles();
            fetchUserRoles();
        }
    }, [open, fetchAllRoles, fetchUserRoles]);

    if (!user || !open) {
        return null;
    }

    return (
        <Modal
            open={open}
            callback={() => setOpen(false)}
            overlayClassName="some-overlay-class"
            shouldCloseOnOverlayClick={true}
            contentLabel="Manage User Roles"
            closeButton
        >
            <h2>Manage Roles for {user.name}</h2>
            {loading && <div>Loading...</div>}
            {responseMessage && <div className="response-message">{responseMessage}</div>}
            {!loading && (
                <table className="roles-list">
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {allRoles.map((role) => (
                        <RoleItem
                            key={role.id}
                            role={role}
                            isUserRole={userRoles.includes(role.name)}
                            onRoleChange={handleRoleChange}
                            disabled={loading}
                        />
                    ))}
                    </tbody>
                </table>
            )}
        </Modal>
    );
};

interface RoleItemProps {
    role: Role;
    isUserRole: boolean;
    onRoleChange: (roleName: string, action: 'add' | 'remove') => void;
    disabled: boolean;
}

const RoleItem: React.FC<RoleItemProps> = ({ role, isUserRole, onRoleChange, disabled }) => (
<>
<tr>
    <td>
        <span>{__(role.name)}</span>
    </td>
    <td>
        <Button
            size="small"
            onClick={() => onRoleChange(role.name, isUserRole ? 'remove' : 'add')}
            className="s-left-sm"
            disabled={disabled}
        >
            {isUserRole ? 'Remove Role' : 'Add Role'}
        </Button>
        </td>
    </tr>
</>
);

export default AddRoleModal;