import React, { useState } from 'react';
import Modal from 'components/Modal';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import middleman from 'helpers/middleman';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { LoadingBar } from 'components/LoadingIndicators';
import { swedishSsn } from "components/Validation";
import ErrorMessage from 'components/ErrorMessage';

const loadingObj = {
  name: <LoadingBar small />,
  national_identity_number: <LoadingBar small />,
};

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
];

const AddViewOnlyUserModal = ({ open, setViewOnlyUsers, viewOnlyUsers, setOpen, portfolioUuid, setInvitedViewOnlyUsers, invitedViewOnlyUsers }) => {

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [amountOfPages, setAmountOfPages] = useState(-1);
  const [email, setEmail] = useState('');
  const [ssn, setSsn] = useState('');
  const [ssnError, setSsnError] = useState(false);

  const addUser = async (user) => {
    if (confirm(`Are you sure you want to add ${user.name} as a view only user?`)) {
      setLoading(true);
      try {
        await middleman.post('/ql/crm/portfolio/add-view-only-user', {
          userUuid: user.uuid,
          portfolioUuid,
        });
        setViewOnlyUsers([...viewOnlyUsers, user]);
      } catch (e) {
        console.error(e.message);
      }
      setLoading(false);
      setOpen(false);
    }
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'National identity number',
      accessor: 'national_identity_number',
    },
    {
      Header: 'Add user',
      Cell: ({ row }) => {
        return (
          <Button
            onClick={() => {
              addUser(row.original);
            }}
            loading={loading}
            disabled={loading}
          >
            Add
          </Button>
        );
      }
    }
  ];

  const handleChange = (value) => {
    setSearchValue(value);
  };

  const fetchPage = async (page, searchParam = false) => {
    setLoading(true);
    setSelectedPage(page - 1);
    const res = await middleman.get(`/ql/crm/user/search?page=${page}${searchParam ? `&search=${searchParam}` : ''}`);
    setAmountOfPages(res.data.last_page);
    setUsers(res.data.data);
    setLoading(false);
  };

  const handlePageFetch = (index) => {
    fetchPage(index + 1, searchValue);
  };

  const search = () => {
    fetchPage(1, searchValue);
  };

  const invite = async (e) => {
    e.preventDefault();
    setSsnError(false);
    const isSsnNotValid = swedishSsn(ssn, 'input', true, false);
    if (isSsnNotValid) {
      setSsnError(true);
      return;
    }
    if (confirm(`Are you sure you want to invite ${email} with SSN ${ssn} as a view only user? A email will be sent to the user.`)) {
      setLoading(true);
      try {
        await middleman.post('/ql/crm/portfolio/invite-view-only-user', {
          email,
          ssn,
          portfolioUuid,
        });
        setInvitedViewOnlyUsers([...invitedViewOnlyUsers, { email, national_identity_number: ssn }]);
        setOpen(false);
      } catch(err) {
        console.error(err.message);
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      callback={() => setOpen(false)}
      closeButton
    >
      <Tabs
        className="line-tabs"
        selectedTabClassName="line-tabs__tab--selected"
        selectedTabPanelClassName="line-tabs__tab-panel--selected"
      >
        <TabList className="line-tabs__tab-list line-tabs__submenu">
          <Tab className="heading heading--5 line-tabs__tab">Add existing user</Tab>
          <Tab className="heading heading--5 line-tabs__tab">Invite none existing user</Tab>
        </TabList>
        <TabPanel>
          <Input
            className='s-top-xl'
            label="Search user by name or SSN"
            type="text"
            callback={handleChange}
            value={searchValue}
          />
          <Button
            onClick={search}
            className='s-bottom-xl'
          >
            Search
          </Button>
          <ApiPaginationTable
            data={users ? users : loadingState}
            headers={columns}
            fetchData={handlePageFetch}
            pageCount={amountOfPages}
            index={selectedPage}
          />
        </TabPanel>
        <TabPanel>
          <form onSubmit={invite}>
            <Input
              className='s-top-xl'
              label="Email"
              type="email"
              callback={(value) => setEmail(value)}
            />
            <Input
              className='s-top-xl'
              label="SSN (YYYYMMDDXXXX)"
              type="text"
              placeholder="YYYYMMDDXXXX"
              callback={(value) => setSsn(value)}
            />
            <ErrorMessage
              text="Invalid SSN, make sure u use the following format YYYYMMDDXXXX"
              showError={ssnError}
            />
            <Button
              className='s-top-xl'
              type="submit"
              loading={loading}
              disabled={loading}
            >
              Invite
            </Button>
          </form>
        </TabPanel>
      </Tabs>
    </Modal>
  );

};

export default AddViewOnlyUserModal;
