import React, { useEffect, useState, Fragment } from 'react';
import middleman from 'helpers/middleman';
import SimpleTable from 'modules/Tables/SimpleTable';
import { LoadingBox } from 'components/LoadingIndicators';
import Heading from 'components/Heading';
import { useTransactions } from 'queries/report';
import TransactionsTable from 'modules/Tables/TransactionsTable';
import { formatNumberByThousands } from 'helpers';
import __ from 'localisation';

const headers = [
  {
    Header: __('currency'),
    accessor: 'accountItem.currency.name',
  },
  {
    Header: __('available_amount'),
    accessor: 'accountItem.expectedAmount',
    Cell: ({ value }) => formatNumberByThousands(value),
  }
];

const ApprovalPremiumItem = ({portfolio, endDate}) => {

  const {
    data: faTransactions,
    isLoading:
    faTransactionsLoading,
    isFetched: faTransactionsFetched
  } = useTransactions(
    '2010-01-01',
    endDate,
    portfolio
      ? portfolio.uuid : null
  );

  return (
    <Fragment key={portfolio.uuid}>
      <Heading size="4" className="s-bottom-l">{portfolio.short_name}</Heading>
      <SimpleTable
        headers={headers}
        data={portfolio.accounts}
      />
      <Heading size="4" className="s-bottom-l s-top-l">{__('transactions')}</Heading>
      <TransactionsTable
        data={
          (faTransactionsFetched && faTransactions && faTransactions.transactions)
            ? faTransactions.transactions
            : []}
        isLoading={faTransactionsLoading}
        currency={portfolio.currency}
      />
    </Fragment>
  )

}

const ApprovalPaidPremiumTable = ({portfolioUuid, endDate}) => {

  const [portfolios, setPortfolios] = useState([]);

  const fetchPaidPremiums = async () => {
    const response = await middleman.get(`/ql/crm/portfolio/paidpremiums/${portfolioUuid}`);

    setPortfolios(response.data);
  }

  useEffect(() => {
    fetchPaidPremiums();
  }, []);


  if (portfolios) {
    return (
      <>
    {portfolios.map((portfolio: any) => (
      <ApprovalPremiumItem portfolio={portfolio} key={portfolio.uuid} endDate={endDate} />
    ))}
      </>
    )
  }

  return <LoadingBox />;
};

export default ApprovalPaidPremiumTable;