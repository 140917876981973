import React, { useState, useEffect } from 'react';
import SimpleTable from 'modules/Tables/SimpleTable';
import Modal from 'components/Modal';
import { useFmaHistory } from 'queries/fma';
import LoadingBar from 'components/LoadingIndicators';
import { getRiskGroup } from 'helpers/fma';

const headers = [
  {
    Header: 'Date',
    accessor: 'created_at',
    width: 60
  },
  // {
  //   Header: 'Rule identifier',
  //   accessor: 'rule.identifier',
  // },
  {
    Header: 'Rule description',
    accessor: 'rule.description',
  },
  {
    Header: 'Score',
    accessor: 'score',
    width: 30,
  },
  {
    Header: 'FMA score',
    accessor: 'scoreAtThisPoint',
    width: 30,
  },
  {
    Header: 'Risk group',
    accessor: 'riskGroup',
    Cell: ({ row }) => (
      getRiskGroup(row.original.scoreAtThisPoint)
    ),
    width: 30,
  },
]

const loadingObj = {
  created_at: <LoadingBar small />,
  rule: {
    identifier: <LoadingBar small />,
    description: <LoadingBar small />,
  },
  score: <LoadingBar small />,
  scoreAtThisPoint: <LoadingBar small />,
}

const loadingState = [
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
  loadingObj,
]

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  profileUuid: string;
  portfolioUuid: string;
};

const FMAHistory = ({open, setOpen, profileUuid, portfolioUuid}: Props) => {

  const { data, isLoading, isFetched } = useFmaHistory(profileUuid, portfolioUuid, open);

  return (
    <Modal
      open={open}
      callback={setOpen}
      closeButton={true}
    >
      <div className='s-top-xl'>
      <SimpleTable
        headers={headers}
        data={isLoading || !isFetched ? loadingState : data}
      />
      </div>
    </Modal>
  )

};

export default FMAHistory;