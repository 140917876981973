import React, { useState, useEffect } from 'react';
import middleman from 'helpers/middleman';
import { useUnbookedTransactions } from 'queries/transactions';
import ApiPaginationTable from 'modules/Tables/ApiPaginationTable';
import { unbookedTransactionsTableHeaders, loadingTableHeaders } from 'helpers/transactions';

const UnbookedTransactions = () => {

  const [page, setPage] = useState<number>(-1);
  const [amountOfPages, setAmountOfPages] = useState<number>(1);
  const [initiated, setInitiated] = useState<boolean>(false);

  const { data, isLoading, isFetched } = useUnbookedTransactions(initiated, page);

  useEffect(() => {
    if (data) {
      setAmountOfPages(data.last_page);
    }
  }, [data]);

  const handlePageFetch = (index) => {
    setPage(index);
    setInitiated(true);
  }

  return (
    <div>
      <ApiPaginationTable
        data={isLoading || !isFetched ? loadingTableHeaders : (data?.data || [])}
        headers={unbookedTransactionsTableHeaders}
        index={page}
        pageCount={amountOfPages}
        fetchData={handlePageFetch}
      />
    </div>
  );
};

export default UnbookedTransactions;