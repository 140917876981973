import React, { useState, useCallback } from 'react';
import FilteredAprovals from './FilteredApprovals';
import ApprovalsByRole from './ApprovalsByRole';
import ArchivedApprovals from './ArchiveApprovals';
import CompletedApprovals from './CompletedApprovals';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { connect } from 'react-redux';
import __ from 'localisation';
import useTabs from 'hooks/useTabs';



const NewApprovals = ({ userRoles }) => {
  const [selected, setSelected] = useState(0);

  const tabs = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ];

  const canView = useCallback((rolesThatCanViewThos) => {
    return userRoles.some((role) => rolesThatCanViewThos.includes(role));
  }, [userRoles]);

  useTabs(setSelected, selected, tabs);

  const canViewHubinsBackoffice = canView(['hubins_backoffice', 'hubins_compliance']);
  const canViewHubinsCompliance = canView(['hubins_backoffice', 'hubins_compliance']);
  const canViewQuantumBackoffice = canView(['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']);
  const canViewQuantumCompliance = canView(['hubins_backoffice', 'hubins_compliance', 'quantum_backoffice', 'quantum_compliance']);
  const canViewFilter = canView(['hubins_backoffice', 'hubins_compliance']);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setSelected}
      selectedIndex={selected}
    >
      <TabList className="line-tabs__tab-list">
        {canViewHubinsBackoffice && <Tab className="heading heading--5 line-tabs__tab">Hubins Backoffice</Tab>}
        {canViewHubinsCompliance && <Tab className="heading heading--5 line-tabs__tab">Hubins Compliance</Tab>}
        {canViewQuantumBackoffice && <Tab className="heading heading--5 line-tabs__tab">QL Backoffice</Tab>}
        {canViewQuantumCompliance && <Tab className="heading heading--5 line-tabs__tab">QL Compliance</Tab>}
        {canViewFilter && <Tab className="heading heading--5 line-tabs__tab">Filter</Tab>}
        <Tab className="heading heading--5 line-tabs__tab">{__('archived_approvals')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('completed_approvals')}</Tab>
      </TabList>
      {canViewHubinsBackoffice && (
        <TabPanel>
          <ApprovalsByRole role="hubins_backoffice" />
        </TabPanel>
      )}
      {canViewHubinsCompliance && (
        <TabPanel>
          <ApprovalsByRole role="hubins_compliance" />
        </TabPanel>
      )}
      {canViewQuantumBackoffice && (
        <TabPanel>
          <ApprovalsByRole role="quantum_backoffice" />
        </TabPanel>
      )}
      {canViewQuantumCompliance && (
        <TabPanel>
          <ApprovalsByRole role="quantum_compliance" />
        </TabPanel>
      )}
      {canViewFilter && (
        <TabPanel>
          <FilteredAprovals />
        </TabPanel>
      )}
      <TabPanel>
        <ArchivedApprovals />
      </TabPanel>
      <TabPanel>
        <CompletedApprovals />
      </TabPanel>
    </Tabs>
  );
};

function mapStateToProps(state) {
  return {
    userRoles: state.auth.User.userRoles
  };
}

export default connect(mapStateToProps)(NewApprovals);
