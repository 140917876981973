import React, { useState } from 'react';
import Button from '@hubins/components/Button';
import Modal from 'components/Modal';
import FileUpload from 'components/FileUpload';
import Icon from '@hubins/components/IconNew';

const CopyCase = (props) => {

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState(false);

  const onReaderLoad = (e) => {
    props.replaceState([]);
    const obj = JSON.parse(e.target.result);
    props.importContent(obj);
  };

  const importData = () => {
    let reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(files[0]);
  };

  const downloadData = (e) => {
    e.preventDefault();
    const data = props.exportData();
    var link = document.createElement('a');
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    link.setAttribute("href", dataStr);
    link.setAttribute("download", "export.json");
    link.click();
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <div className="copy_case align-right">
      <Modal open={open} noWrapper>
        <div className="wrapper copy_case__upload">
          <FileUpload accept="application/json" callback={setFiles} />
          <div className="button__wrap">
            <Button disabled={!files} className="cta spacing" onClick={() => { importData(); }}>Import</Button>
            <Button className="spacing" onClick={toggleModal}>Close</Button>
          </div>
        </div>
      </Modal>
      <Button className="small link withicon withicon--left" onClick={toggleModal}>
        <Icon icon="Upload Bottom by Streamlinehq" />Import
      </Button>
      <Button className="small link withicon withicon--left" onClick={(e) => { downloadData(e); }}>
        <Icon icon="Download Bottom by Streamlinehq" />Export
      </Button>
    </div>
  );

};




export default CopyCase;
