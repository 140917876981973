import React, { Component, Fragment } from 'react';
import { Columns, Column } from 'components/Columns';
import TextEditor from 'components/TextEditor';
// import { EditorState, convertFromRaw } from 'draft-js';
import Button, { ButtonWrap } from '@hubins/components/Button';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';
import { __ } from 'localisation';

class CaseTableEditLoaded extends Component {

  constructor(props) {
    super(props);
    this.state = {

      title: 'value' in props ?
        props.value.title :
        '',

      type: "detail-table",
      id: props.id,

      rows: 'value' in props ?
        this.convertRows(props.value.rows) :
        [{ title: '', text: props.EditorState.createEmpty() }]
    };

    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.handleMainTitle = this.handleMainTitle.bind(this);
  }

  componentDidMount() {
    this.props.callback(this.props.id,
      {
        title: this.state.title,
        type: this.state.type,
        id: this.state.id,
        rows: this.state.rows,
      }
    );
  }

  convertRows(rows) {
    rows = rows.map(row => (
      { ...row, text: this.props.EditorState.createWithContent(this.props.convertFromRaw(row.text)) }
    ));
    return rows;
  }

  handleTitleChange(value, index) {
    const newState = this.state.rows;
    newState[index].title = value;

    this.setState({
      rows: newState
    });

    this.props.callback(this.props.id, {
      title: this.state.title,
      type: this.state.type,
      id: this.state.id,
      rows: newState
    });
  }

  handleTextChange(value, index) {
    const newState = this.state.rows;
    newState[index].text = value;

    this.setState({
      rows: newState
    });

    this.props.callback(this.props.id, {
      title: this.state.title,
      type: this.state.type,
      id: this.state.id,
      rows: newState
    });
  }

  handleMainTitle(e) {

    this.setState({
      title: e.target.value
    });

    this.props.callback(this.props.id, {
      title: e.target.value,
      type: this.state.type,
      id: this.state.id,
      rows: this.state.rows
    });

  }

  addRow() {
    let stateRows = this.state.rows;
    let newRow = { title: '', text: '' };
    stateRows.push(newRow);

    this.setState({
      rows: stateRows
    });

    this.props.callback(this.props.id, {
      title: this.state.title,
      type: this.state.type,
      id: this.state.id,
      rows: stateRows
    });

  }

  removeRow() {
    let stateRows = this.state.rows;
    stateRows.pop();

    this.setState({
      rows: stateRows
    });

    this.props.callback(this.props.id, {
      title: this.state.title,
      type: this.state.type,
      id: this.state.id,
      rows: stateRows
    });
  }

  renderRows() {
    return this.state.rows.map((row, index) => (
      <Columns key={index} className={index % 2 === 0 ? '' : 'neutral-1'}>
        <Column s="4">
          <label>
            <div className="label">Titel</div>
            <input id={`title-${index}`} className="input" onChange={(e) => { this.handleTitleChange(e.target.value, index); }} value={this.state.rows[index].title} />
          </label>
        </Column>
        <Column s="8">
          <div className="label">Text</div>
          <TextEditor onEditorStateChange={(editorState) => { this.handleTextChange(editorState, index); }} editorState={this.state.rows[index].text} />
        </Column>
      </Columns>
    ));
  }

  render() {
    return (
      <Fragment>
        <input className="input" value={this.state.title} onChange={this.handleMainTitle} placeholder="Titel" />
        {this.renderRows()}
        <ButtonWrap center>
          <Button className="spacing" icon="add" iconLeft onClick={this.addRow}>{__('add_row')}</Button>
          <Button className="spacing" icon="Bin by Streamlinehq" iconLeft onClick={this.removeRow}>{__('remove_row')}</Button>
        </ButtonWrap>
      </Fragment>
    );
  }

}


const CaseTableEdit = Loadable.Map({
  loader: {
    EditorState: () => import('draft-js/lib/EditorState'),
    convertFromRaw: () => import('draft-js/lib/convertFromRawToDraftState'),
  },
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <CaseTableEditLoaded {...props}
      EditorState={loaded.EditorState.default}
      convertFromRaw={loaded.convertFromRaw.default}
    />;
  },
});

export default CaseTableEdit;
