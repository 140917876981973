import React, { useState } from 'react';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Modal from 'components/Modal';
import __ from 'localisation';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';

type Props = {
  profileUuid: string,
  portfolioUuid: string,
}

const CreateApproval = ({profileUuid, portfolioUuid}: Props) => {

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);

  const { setNotification } = useNotificationContext();

  const openModal = (e) => {
    e.preventDefault();
    setOpen(true);
  }

  const createSofApproval = async () => {
    try {
      setLoading(true);
      await middleman.promisePost("/ql/crm/approval-presets/sof", {
        profileUuid,
      })
      setNotification({ type: 'success', message: <>Successfully created <strong>Source of Funds</strong> approval</> });
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
      setNotification({ type: 'error', message: <>Failed to create <strong>Source of Funds</strong> approval</> });
      setLoading(false);
    }
  }

  const createPolicyApproval = async () => {
    try {
      setLoading(true);
      await middleman.promisePost("/ql/crm/approval-presets/policy", {
        profileUuid,
        portfolioUuid,
      })
      setNotification({ type: 'success', message: <p>Successfully created <strong>Policy</strong> approval</p> });
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.error(error);
      setNotification({ type: 'error', message: <p>Failed to create <strong>Policy</strong> approval</p> });
      setLoading(false);
    }
  }

  return (
    <>
      <Button onClick={openModal} icon="add" className='s-top-xl s-bottom-xl'>
        {__('create_approval')}
      </Button>
      <Modal
        open={open}
        callback={setOpen}
        closeButton={true}
      >
        <Heading size={3} className="s-bottom-l s-top-l">{__('create_approval')}</Heading>
        <ButtonWrap>
          <Button
            className=""
            onClick={createPolicyApproval}
            loading={loading}
            disabled={loading}
            icon="add"
          >
            {__('create_policy_approval')}
          </Button>
        </ButtonWrap>
        <ButtonWrap>
          <Button
            className='s-top-sm s-bottom-l'
            onClick={createSofApproval}
            loading={loading}
            disabled={loading}
            icon="add"
          >
            {__('create_sof_approval')}
          </Button>
        </ButtonWrap>

      </Modal>
    </>
  )

};

export default CreateApproval;