import React, { useEffect, useState } from 'react';
import Wrapper from '@hubins/components/Wrapper';
import Heading from 'components/Heading';
import SimpleTable from 'modules/Tables/SimpleTable';
import { LoadingBox } from 'components/LoadingIndicators';
import middleman from 'helpers/middleman';

const Headers = [
  {
    Header: 'Name',
    accessor: 'profileName',
  },
  {
    Header: 'Portfolio shortname',
    accessor: 'portfolioShortname',
  },
  {
    Header: 'SSN/Org nr',
    accessor: 'profileNationalIdentityNumber',
  },
  {
    Header: 'type',
    accessor: 'type',
    Cell: ({ row }) => row.original.key === 'c_royc' ? 'Company' : 'Private person'
  },
];


const ROYCList = () => {

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchList = async () => {
    setLoading(true);
    try {
      const response = await middleman.get('/ql/crm/royc/list');
      setList(response.data);
    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <Wrapper>
      <Heading>ROYC List</Heading>
      {loading ? (
        <LoadingBox />
      ) : (
        <SimpleTable
          data={list}
          headers={Headers}
        />
      )}
    </Wrapper>
  );
};

export default ROYCList;
