import React, { useState, useEffect } from 'react';
import { Column, Columns } from 'components/Columns';
import SimpleTable from 'modules/Tables/SimpleTable';
import ExpandableTable from '@hubins/components/ExpandableTable';
import { useTofTransactions } from 'queries/transactions';
import { useBatches } from 'queries/batches';
import Heading from 'components/Heading';
import __ from 'localisation';
import { batchListHeaders, batchHeadersWithDelete } from 'helpers/batches';


const PendingBatches = () => {

  const { data, isLoading, refetch: refetchTransactions } = useTofTransactions('pending');
  const { data: batches, isLoading: isBatchesLoading, refetch: refetchBatches } = useBatches('TOF', 'pending');
  const [securities, setSecurities] = useState<any>({});

  const refetch = () => {
    refetchTransactions();
    refetchBatches();
  }

  useEffect(() => {
    const indexedSecurities = data?.reduce((acc, transaction) => {
      acc[transaction.security_id] = transaction.security;
      return acc;
    }, {});
    if (indexedSecurities) {
      setSecurities(indexedSecurities);
    }
  }, [data, batches])

  return (
    <>
      <Columns>
        <Column s="12">
          <Heading size={4} className='s-bottom-lg'>{__('order_processes')}</Heading>
          <ExpandableTable
            headers={batchHeadersWithDelete(refetch)}
            data={batches || []}
            component={(row) => (
              <div className="neutral-1">
                <SimpleTable headers={batchListHeaders(refetch)} data={row.transactions ? row.transactions.map((transaction: any) => ({
                  ...transaction,
                  security: securities[transaction.security_id] || {}
                })) || [] : []} />
              </div>
            )}
          />
        </Column>
      </Columns>
    </>
  )
};

export default PendingBatches;