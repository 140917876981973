import React, { Component } from 'react';
import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';

// import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './index.scss';
const LoadingSVG = (props) => (
  <LoadingBox {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" className="placeholder-svg" preserveAspectRatio="none" viewBox="0 0 600 200"><title>Loading editor</title><path fill="#f2f2f5" d="M0 40h600v160H0z" /><path fill="#fff" d="M0 0h600v40H0z" /><path fill="#f2f2f5" d="M10 10h20v20H10zM35 10h20v20H35zM125 10h20v20h-20zM150 10h20v20h-20zM175 10h20v20h-20zM200 10h20v20h-20zM225 10h20v20h-20zM60 10h60v20H60z" /></svg>
  </LoadingBox>
);

const LoadableEditor = Loadable({
  loader: () => import('react-draft-wysiwyg' /* webpackChunkName: "react-draft-wysiwyg" */),
  loading: LoadingSVG,
  timeout: 120000, // 2 min
  render(loaded, props) {
    let Editor = loaded.Editor;
    return <Editor {...props} />;
  }
});


class TextEditor extends Component {

  render() {
    let toolbar = {
      // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
      options: ['inline', 'link', 'remove', 'history'],
      inline: {
        options: ['bold', 'italic'],
      },
      blockType: {
        options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
      },
    };

    this.props.extended && (toolbar.options = ['inline', 'blockType', 'list', 'link', 'remove', 'history']);

    return (
      <LoadableEditor
        stripPastedStyles={true}
        wrapperClassName={this.props.large ? 'rdw-editor-wrapper--large fc' : 'fc'}
        editorState={this.props.editorState}
        onEditorStateChange={this.props.onEditorStateChange}
        toolbar={toolbar}
      />
    );
  }

}

export default TextEditor;
