import React, { useState, useEffect } from 'react';
import Input from '@hubins/components/Input';
import Button from '@hubins/components/Button';
import __ from 'localisation';
import { Column, Columns } from 'components/Columns';
import ChooseSecuritiesInput from '@hubins/components/ChooseSecuritiesInput';
import ChoosePortfolioInput from '@hubins/components/ChoosePortfolioInput';
import DateInput from '@hubins/components/DateInput';
import Dropdown from '@hubins/components/Dropdown';
import { currencyOptions } from 'helpers/currency';
import middleman from 'helpers/middleman';
import { useNotificationContext } from '@hubins/components/NotificationContext/NotificationContext';
import ErrorMessage from 'components/ErrorMessage';
import FileUpload from 'components/FileUpload';
import ExchangeRateInput from '@hubins/components/ExchangeRateInput';
import UnitPriceInput from '@hubins/components/UnitPriceInput';
import TradeAmountInput from '@hubins/components/TradeAmountInput';
import DecimalInput from '@hubins/components/DecimalInput';

const paidPremiumTypeOptions = [
  {
    id: 'cash',
    title: __('liquid_funds')
  },
  {
    id: 'security',
    title: __('security')
  }
]

const CreatePaidPremium = () => {
  const [selectedPortfolio, setSelectedPortfolio] = useState<any>(null);
  const [selectedSecurity, setSelectedSecurity] = useState<any>(null);
  const [transactionDate, setTransactionDate] = useState<any>(null);
  const [unitPrice, setUnitPrice] = useState<any>(null);
  const [units, setUnits] = useState<any>(null);
  const [currency, setCurrency] = useState<any>(null);
  const [exchangeRate, setExchangeRate] = useState<any>(null);
  const [accruedInterest, setAccruedInterest] = useState<any>(null);
  const [paidPremiumType, setPaidPremiumType] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);

  const { setNotification } = useNotificationContext();

  useEffect(() => {
    if (error) {
      setNotification({
        type: 'error',
        message: error
      });
    }
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {

      if (!paidPremiumType) {
        throw new Error(__('paid_premium_type_required'));
      }

      if (!selectedPortfolio) {
        throw new Error(__('portfolio_required'));
      }

      if (paidPremiumType === 'security' && !selectedSecurity) {
        throw new Error(__('security_required'));
      }

      const isSecurity = paidPremiumType === 'security';
      const updateBody = {
        portfolioShortName: selectedPortfolio.short_name,
        type: isSecurity ? 'ADD' : 'DEP',
        transactionDate,
        units,
        unitPrice: isSecurity ? unitPrice : 1,
        currency: isSecurity ? selectedSecurity.currency : currency,
        exchangeRate,
        accruedInterest: isSecurity ? accruedInterest : undefined,
        securityId: isSecurity ? selectedSecurity.id : undefined,
      }
      const response = await middleman.post('/ql/crm/fa/transaction/store', updateBody);
      const transactionUuid = response.data.uuid;

      if (files && files.length > 0) {
        const fileUploadPromises = files.map(file => {
          const formData = new FormData();
          formData.append('file', file);
          return middleman.post(`/ql/crm/transaction/upload-file/${transactionUuid}`, formData);
        });
        await Promise.all(fileUploadPromises);
      }

      setNotification({
        type: 'success',
        message: __('paid_premium_created')
      });

    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError(error.message);
      }
    }
    setLoading(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="s-top-xl">
        <Columns>
          <Column s="12">
            <Dropdown
              className='input-max-width'
              label={__('paid_premium_type')}
              value={paidPremiumType}
              callback={setPaidPremiumType}
              placeholder={__('choose_paid_premium_type')}
              options={paidPremiumTypeOptions}
              required={true}
            />
          </Column>
          <Column xl="6">
            <ChoosePortfolioInput callback={setSelectedPortfolio} />
          </Column>
          {paidPremiumType === 'security' && (
            <Column xl="6">
              <ChooseSecuritiesInput callback={setSelectedSecurity} />
            </Column>
          )}
          <Column l="6">
            <DateInput
              label={__('choose_transaction_date')}
              value={transactionDate}
              callback={setTransactionDate}
            />
          </Column>
          {paidPremiumType === 'security' && (
            <Column l="6">
              <UnitPriceInput
                value={unitPrice}
                callback={setUnitPrice}
                securityId={selectedSecurity?.id}
              />
            </Column>
          )}
          <Column l="6">
            <DecimalInput
              label={__('choose_units')}
              value={units}
              callback={setUnits}
            />
          </Column>
          <Column l="6">
            <ExchangeRateInput
              currency={paidPremiumType === 'security' ? selectedSecurity?.currency : currency}
              callback={setExchangeRate}
              value={exchangeRate}
            />
          </Column>
          <Column l="6">
            {selectedSecurity ? (
              <Input
                label={__('currency')}
                value={selectedSecurity.currency}
                disabled={true}
                controlled={true}
              />
            ) : (
              <Dropdown
                value={currency}
                label={__('currency')}
                options={currencyOptions}
                callback={setCurrency}
                placeholder={__('choose_currency')}
              />
            )}
          </Column>
          {paidPremiumType === 'security' && (
            <Column l="6">
              <Input
                label={__('accrued_interest_label')}
                value={accruedInterest}
                callback={setAccruedInterest}
              />
            </Column>
          )}
          <Column l="6">
            <TradeAmountInput
              units={units}
              unitPrice={paidPremiumType === 'security' ? unitPrice : 1}
              exchangeRate={exchangeRate}
              currency={'SEK'}
              accruedInterest={accruedInterest}
              multiplier={paidPremiumType === 'security' ? selectedSecurity?.multiplier : 1}
            />
          </Column>
          <Column s="12">
            <FileUpload
              label={__('choose_files')}
              callback={setFiles}
            />
          </Column>
          <Column s="12">
            <Button
              className="cta"
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
            >
              {__('create_paid_premium')}
            </Button>
            <ErrorMessage text={error} showError={!!error} />
          </Column>
        </Columns>
      </form>
    </>
  )
};

export default CreatePaidPremium;