import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Item from './Item';

import Loadable from 'jaybe-react-loadable';
import { LoadingBox } from 'components/LoadingIndicators';

import './index.scss';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class SortableListLoaded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      itemToDelete: null,
      deletedItem: null,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    // this.onSortEnd = this.onSortEnd.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }
  // onDragStart(result) {
  //   console.log(result);

  // }
  onDragEnd(result) {
    const { destination, source } = result;

    // Eg if dropping outside Droppable
    if (!destination) {
      return;
    }

    // User drop into its org position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // reorder the items array
    const newItems = reorder(
      this.props.items,
      // this.state.items,
      result.source.index,
      result.destination.index
    );

    this.props.updateState(newItems);

    // this.setState({
    //   items: newItems
    // });
  }

  removeItem(index, type) {
    if (type === 'slide') {
      // open
      if (this.state.itemToDelete === null) {
        this.setState({
          itemToDelete: index
        });
      }

      // Close
      else {
        this.setState({
          itemToDelete: null
        });
      }
    }
    else if (type === 'delete') {
      this.deleteItem(index);
    }
  }

  deleteItem(index) {
    const { items } = this.props;

    // remove index from items
    const newItems = items.filter(item => item.id !== items[index].id);

    this.setState({
      itemToDelete: null,
      deletedItem: index
    });

    this.removeTimeout = setTimeout(() => {

      this.props.updateState(newItems);
      this.setState({
        // items: newItems,
        itemToDelete: null,
        deletedItem: null,
      });

    }, 500);
  }

  render() {
    const { DragDropContext, Droppable } = this.props;

    return (
      <div className="sortable-list__wrap">
        <DragDropContext
          // onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}>
          <Droppable droppableId={'sortableList'}>
            {provided => (
              <div
                className="sortable-list"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {this.props.items.map((item, index) => {
                  let status = null;
                  if (this.state.itemToDelete === index) status = 'slide';
                  else if (this.state.deletedItem === index) status = 'delete';
                  return (
                    <Item
                      status={status}
                      key={item.id}
                      item={item}
                      index={index}
                      removeCallback={this.removeItem}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="sortable-addcomponents">
            {this.props.addComponents}
          </div>
        </DragDropContext>
      </div>
    );
  }
}

const SortableList = Loadable({
  loader: () => import('react-beautiful-dnd' /* webpackChunkName: "react-beautiful-dnd" */),
  loading: LoadingBox,
  timeout: 120000, // 2 min
  render(loaded, props) {
    return <SortableListLoaded {...props}
      DragDropContext={loaded.DragDropContext}
      Droppable={loaded.Droppable}
    />;
  },
});

SortableList.propTypes = {
  items: PropTypes.array
};

SortableList.defaultProps = {
  items: []
};

export default SortableList;
