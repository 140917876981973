import React, { useState, useEffect, useMemo } from 'react';
import {
  refreshToken,
  logout,
  // setStatus
} from '../../state/ducks/auth';
import { connect } from 'react-redux';
import Modal from 'components/Modal';

import Icon from '@hubins/components/IconNew';
import Button from '@hubins/components/Button';
import PropTypes from 'prop-types';
import __ from 'localisation';
import useInterval from 'hooks/useInterval';
import { removeItem } from 'helpers';
import Heading from 'components/Heading';
import { REFRESH_BUFFER } from 'helpers/idleTimer';

const CHECK_INTERVAL = 4000; // in ms
// const WARNING_TIME = 120000; // in ms (2min before logging out)
const WARNING_TIME = 30000; // in ms (0.5min before logging out)
const INACTIVE_BUFFER = 5000;
const STORE_KEY = 'lastAction';

const interactions = ['click', 'touchstart', 'touchend', 'mousemove', 'keydown', 'keyup', 'keypress'];

export const IdleTimer = (props) => {
  const _DEBUG = false;

  const getNow = () => { return Date.now(); };
  const tokenStates = { OK: 'ok', EXPIRED: 'expired', SOON: 'soon' };
  const [tokenState, setTokenState] = useState(tokenStates.OK);
  const [lastActionTime, setLastActionTime] = useState(getNow());

  const timeSinceLastAction = () => {
    return getNow() - lastActionTime;
  };

  const timeLeftOnToken = () => {
    return props.tokenExpires - getNow();
  };

  const checkIsIrrelevant = () => {
    if (tokenState.EXPIRED) { return true; }
    return false;
  };

  useEffect(() => {
    // Hängslen och livrem
    if (tokenState === tokenStates.OK) return;
    if (tokenState === tokenStates.SOON) return;
    if (timeLeftOnToken() > 0) return;
    // Else tokenstate is expired
    props.logout();
  }, [tokenState]);

  // Run on mount
  const checkLogout = () => {
    if (checkIsIrrelevant()) return;
    if (timeLeftOnToken() < 0) {
      setTokenState(tokenStates.EXPIRED);
      return true;
    }
    return false;
  };

  const inactiveUser = () => {
    // If last action was within buffer, user is active
    if (timeSinceLastAction() < INACTIVE_BUFFER) { return false; }
    return true;
  };

  const activeUser = () => { return !inactiveUser(); };

  const check = () => {
    _DEBUG && console.log('IdleTimer, should not check?', checkIsIrrelevant());
    if (checkIsIrrelevant()) { return; }


    const _timeLeftOnToken = timeLeftOnToken();
    _DEBUG && console.log('TimeLeft: ', _timeLeftOnToken);

    if (_timeLeftOnToken < 0) {
      setTokenState(tokenStates.EXPIRED);
      return;
    }

    if (_timeLeftOnToken < REFRESH_BUFFER && activeUser()) {
      _DEBUG && console.log('Refresh token on active user');
      props.refreshToken();
      return;
    }

    if (_timeLeftOnToken < WARNING_TIME && inactiveUser()) {
      _DEBUG && console.log('Warn inactiveUser');
      setTokenState(tokenStates.SOON);
      return;
    }
  };

  const reset = () => {
    setLastActionTime(getNow());
    if (tokenState === tokenStates.SOON) {
      setTokenState(tokenStates.OK);
    }
  };

  const initListeners = () => {
    interactions.forEach(interaction => {
      window.addEventListener(interaction, reset);
    });
  };

  const removeListeners = () => {
    interactions.forEach(interaction => {
      window.removeEventListener(interaction, reset, false);
    });
  };


  useInterval(() => {
    check();
  }, CHECK_INTERVAL);

  useEffect(() => {
    initListeners();
    checkLogout();
    return () => {
      removeListeners();
      removeItem(STORE_KEY);
    };
  }, [props.tokenExpires]);

  const closeThis = () => {
    setTokenState(tokenStates.OK);
  };
  //
  return useMemo(() => {

    return (
      <Modal
        className="animation-shake"
        open={tokenState === tokenStates.EXPIRED || tokenState === tokenStates.SOON}
      >
        <Icon
          icon="Sign Warning"
          size={24}
          color="primary"
          className="s-bottom-m"
        />
        <Heading size="3">
          {tokenState === tokenStates.SOON ?
            (__('logout_modal_title')) :
            (__('token_has_expired'))
          }

        </Heading>
        <p>
          {tokenState === tokenStates.SOON ?
            (__('logout_modal_desc')) :
            (__('token_has_expired_desc'))
          }
        </p>
        <Button onClick={closeThis}>{__('close')}</Button>
      </Modal>
    );
  }, [tokenState]);



};

IdleTimer.propTypes = {
  refreshToken: PropTypes.func,
  logout: PropTypes.func,
  // setStatus: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    tokenExpires: state.auth.User.tokenExpires
  };
}

export default connect(
  mapStateToProps,
  {
    refreshToken,
    logout,
    // setStatus,
  }
)(IdleTimer);
