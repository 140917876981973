import React, { useState } from 'react';
import Input from '@hubins/components/Input';
import Heading from 'components/Heading';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Modal from 'components/Modal';
import middleman from 'helpers/middleman';
import { connect } from 'react-redux';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  updateUser: (data: any) => void;
  QRCode: string;
  token?: string;
};

const ActivateTFAModal = ({open, setOpen, updateUser, QRCode, token = undefined}: Props) => {

  const [verificationCode, setVerificationCode] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const submit = async () => {
    try {
      setLoading(true);
      const res = await middleman.post('/auth/tfa/confirm', {
        code: verificationCode,
        token,
      });
      setLoading(false);
      setSuccess(true);
      updateUser(res.data);
      // Update user
    } catch (e) {
      console.error(e.message);
    }
  };

  return (
    <Modal open={open} overlayClassName="flex-center" callback={setOpen}>
        <>
        {success ? (
          <>
            <Heading size="3" className="s-md">Two factor authentication has been activated</Heading>
            <ButtonWrap center>
              <Button
                className="cta"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </ButtonWrap>
          </>
        ) : (
          <>
            <Heading size="2" className="s-md">Scan QR Code</Heading>
            <div
              dangerouslySetInnerHTML={{ __html: QRCode }}
            >
            </div>
            <ol className='section-align align-left s-md'>
              <li>Download the Google Authenticator app on your mobile phone.</li>
              <li>In the Google Authenticator app, choose to scan the QR code to add the authenticator.</li>
              <li>Write down the 6 digit code below and press Verify.</li>
            </ol>
            <Input
              type="tel"
              className="maxwidthform"
              value={verificationCode}
              label="Verification code 6 digits"
              required
              callback={setVerificationCode}
            />
            <ButtonWrap center>
              <Button
                className="cta"
                loading={loading}
                disabled={loading}
                onClick={submit}
              >
                Verify
              </Button>
              <Button
                onClick={() => setOpen(false)}
              >
                Abort
              </Button>
            </ButtonWrap>
          </>
        )}
        </>
    </Modal>
  )

};

export default ActivateTFAModal;