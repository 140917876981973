import React from 'react';
import Modal from 'components/Modal';
import Icon from '@hubins/components/IconNew';
import Button, { ButtonWrap } from '@hubins/components/Button';
import Heading from 'components/Heading';

const InvalidModal = ({ open, setOpen, items }) => {

  return (
    <Modal open={open} callback={setOpen} closeButton contentClass="c-danger-border standard-border">
      <Icon icon="Sign Warning" size={24} className='s-bottom-l' />
      {items.map((item, index) => (
        <Heading size="5" key={index} className="s-top-m">
          {item.msg}
        </Heading>
      ))}
      <ButtonWrap>
        <Button icon="remove" onClick={() => {setOpen(false);}} className='s-top-l'>Close</Button>
      </ButtonWrap>
    </Modal>
  );

};

export default InvalidModal;
