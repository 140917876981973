import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'components/Tabs';
import { __ } from 'localisation';
import useTabs from 'hooks/useTabs';
import Registration from 'views/Client/Registration';
import AptitudeTests from 'views/Client/AptitudeTests';
import Roles from 'views/Client/Roles';
import Tags from 'views/Client/Tags';

const tabs = [
  'forms',
  'aptitude-tests',
  'roles',
  'tags'
];

type Props = {
  portfolioUuid: string;
  profileUuid: string;
  client: any;
  portfolio: any;
}

const RegistrationTabs = ({ portfolioUuid, profileUuid, client, portfolio }: Props) => {

  const [index, setIndex] = useState(0);
  useTabs(setIndex, index, tabs);

  return (
    <Tabs
      className="line-tabs"
      selectedTabClassName="line-tabs__tab--selected"
      selectedTabPanelClassName="line-tabs__tab-panel--selected"
      onSelect={setIndex}
      selectedIndex={index}
    >
      <TabList className="line-tabs__tab-list line-tabs__submenu">
        <Tab className="heading heading--5 line-tabs__tab">{__('menu_forms')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('aptitude_tests')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('roles')}</Tab>
        <Tab className="heading heading--5 line-tabs__tab">{__('tags')}</Tab>
      </TabList>
      <TabPanel>
        <Registration
          portfolioUuid={portfolioUuid}
          profileUuid={profileUuid}
          contact={client}
          portfolio={portfolio}
        />
      </TabPanel>
      <TabPanel className={`line-tabs__tab-panel`}>
        <AptitudeTests
          client={client}
        />
      </TabPanel>
      <TabPanel>
        <Roles
          portfolio={portfolio}
          profileUuid={profileUuid}
        />
      </TabPanel>
      <TabPanel>
        <Tags
          portfolio={portfolio}
          profileUuid={profileUuid}
        />
      </TabPanel>
    </Tabs>
  );
};

export default RegistrationTabs;