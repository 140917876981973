import React, { useEffect, useState } from 'react';
import middleman from 'helpers/middleman';
import Heading from 'components/Heading';
import { LoadingBox } from 'components/LoadingIndicators';
import PortfolioRegistration from '@hubins/components/PortfolioRegistration';
import ProfileRegistration from '@hubins/components/ProfileRegistration';
import SimpleTable from 'modules/Tables/SimpleTable';
import ApprovalProfilePortfolioSummary from '@hubins/components/ApprovalProfilePortfolioSummary';

import __ from 'localisation';

const policyInformationHeaders = [
  {
    Header: '',
    accessor: 'title',
  },
  {
    Header: '',
    accessor: 'value',
  }
];

const getPortfolioRows = (portfolio) => {
  return [
    {
      title: __('creation_date'),
      value: portfolio.start_date || portfolio.created_at,
    },
    {
      title: __('currency'),
      value: portfolio.currency,
    },
  ];
};

const getPolicyHolderRows = (holder) => {
  return [
    {
      title: __('name'),
      value: holder.name,
    },
    {
      title: __('nin'),
      value: holder.national_identity_number,
    },
  ];
};

const Portfolio = ({
  portfolio_uuid: portfolioUuid,
  profile_uuid: profileUuid,
  endDate
}) => {

  const [profile, setProfile] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setPortfolio(false);
    setProfile(false);
    setLoading(true);
    setError(false);
    try {
      const profilePromise = middleman.promiseGet(`/profile/${profileUuid}`);
      const portfolioPromise = middleman.promiseGet(`/portfolio/${portfolioUuid}`);
      const res = await Promise.all([profilePromise, portfolioPromise]);
      setProfile(res[0].data);
      setPortfolio(res[1].data);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return (<LoadingBox />);

  if (error) return (
    <Heading size="2" center className="s-top-xl">
      Something went wrong: {error}
    </Heading>);

  return (
    <div>
      <Heading size="4" className="s-bottom-xl s-top-xl">
        {__('policy_information')}
      </Heading>
      <ApprovalProfilePortfolioSummary portfolio={portfolio} portfolioUuid={portfolio?.uuid} />
      <SimpleTable
        headers={policyInformationHeaders}
        data={getPortfolioRows(portfolio)}
      />
      <Heading size="4" className="s-bottom-l s-top-xl">Policy Roles</Heading>
      {portfolio.policy_holder.map((holder, index) => (
        <>
          <Heading size="5" className="s-bottom-s">
            {__('policy_holder')} {index + 1}
          </Heading>
          <SimpleTable
            headers={policyInformationHeaders}
            data={getPolicyHolderRows(holder)}
          />
        </>
      ))}
      {portfolio.life_assured.map((assured, index) => (
        <>
          <Heading size="5" className="s-bottom-s s-top-xl">
            {__('life_assured')} {index + 1}
          </Heading>
          <SimpleTable
            headers={policyInformationHeaders}
            data={getPolicyHolderRows(assured)}
          />
        </>
      ))}
      {portfolio.beneficial.map((beneficial, index) => (
        <>
          <Heading size="5" className="s-bottom-s s-top-xl">
            {__('beneficiary')} {index + 1}
          </Heading>
          <SimpleTable
            headers={policyInformationHeaders}
            data={getPolicyHolderRows(beneficial)}
          />
        </>
      ))}
      <Heading size="4" className="s-bottom-xl s-top-xl">
        {__('profile_attributes')}
      </Heading>
      <ProfileRegistration
        profile={profile}
        profileUuid={profileUuid}
        endDate={endDate}
      />
      <Heading size="4" className="s-bottom-xl s-top-xl">
        {__('portfolio_attributes')}
      </Heading>
      <PortfolioRegistration
        profile={profile}
        portfolioUuid={portfolioUuid}
        profileUuid={profileUuid}
        endDate={endDate}
      />
    </div>
  );
};

export default Portfolio;
