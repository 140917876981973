import React from 'react';
import ExpandableFormSummary from 'modules/ExpandableFormSummary';
import './index.scss';

const SettingsExpandableFormSummary = ({ formKey, portfolioUuid, editLink, summary, title, className, titleSize, profileUuid, endDate }) => {
  return (
    <div className={`settings-expandable-form-summary ${className}`}>
      <ExpandableFormSummary
        titleSize={titleSize}
        formKey={formKey}
        portfolioUuid={portfolioUuid}
        profileUuid={profileUuid}
        editLink={editLink}
        summary={summary}
        title={title}
        endDate={endDate}
      />
    </div>
  )
};

export default SettingsExpandableFormSummary;