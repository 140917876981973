import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authOperations } from '../../state/ducks/auth';
import Heading from 'components/Heading';
import { __ } from 'localisation';

import middleman from 'helpers/middleman';

const LoggedOut = ({ logout, timer }) => {

  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    middleman.post(`/v1/fa/logout`, null);
    logout();
    const myTimer = setTimeout(() => {
      setRedirect(true);
    }, timer);
    return () => {
      clearTimeout(myTimer);
    };
  }, []);

  return (
    <main className="main main--center" id="MainContent" data-shiftonsidebaropen>
      <div className="center">
        <img src="/illustrations/login.svg" alt="A locked lock" className="illustration illustration--medium" />
        <Heading size="3">{__('logout_title')}</Heading>
        <p>{__('logout_description')}</p>
        {redirect && <Redirect to="/" />}
      </div>
    </main>
  );
};

LoggedOut.defaultProps = {
  timer: 2000
};


export default connect(null, { logout: authOperations.logout })(LoggedOut);
