import React from 'react';
import { __SE as __ } from 'localisation/hubins/registration';
import ReadMore from 'components/Accordeon/ReadMore';

const GraphDisclaimer = ({ prefix }) => {
  return (
    <ReadMore
      collapsedHeight={150}
      hiddenContent={
        <>
          <div className="p clarification maxwidth body-small">
            {__(`${prefix}graph_page_content_2`)}
          </div>
          <div className="p clarification maxwidth body-small">
            {__(`${prefix}graph_page_content_3`)}
          </div>
        </>
      }
    />
  );};

export default GraphDisclaimer;
