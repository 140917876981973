import React, { useRef } from 'react';
import { __ } from 'localisation';
import Heading from 'components/Heading';
import { useHoldings, useReport } from 'queries/report';
import HoldingsTable from 'modules/Tables/Holdings';
import AccountsTable from 'modules/Tables/AccountsTable';
import { Columns, Column } from 'components/Columns';
import { getMoney } from 'helpers';

const Holdings = ({ portfolioUuid, portfolio }) => {

  const endDate = useRef(new Date().toISOString().substr(0, 10));
  const startDate = '2010-01-01';

  const { data, isLoading } = useHoldings(startDate, endDate.current, portfolioUuid);
  const { data: report, isLoading: reportLoading, isFetched: reportIsFetched } = useReport(startDate, endDate.current, portfolioUuid);

  return (
    <Columns>
      <Column s="12">
        <Heading size="4" className="s-top-xl">
          Totalt värde
        </Heading>
        <Heading size="2" className="s-bottom-xl">
          {getMoney(portfolio?.market_value)}
        </Heading>
      </Column>
      <Column l="6">
      </Column>
      <Column s="12">
        <Heading size="4" className="section-padding">
          {__('liquid_funds')}
        </Heading>
        <AccountsTable
          currency={reportIsFetched ? report.accounts.currency : ''}
          isLoading={reportLoading}
          accounts={reportIsFetched ? report.accounts : []}
        />
      </Column>
      <Column s="12">
        <HoldingsTable
          holdings={data}
          loading={isLoading}
          currency={reportIsFetched ? report.accounts.currency : ''}
        />
      </Column>
    </Columns>
  );

};

export default Holdings;
