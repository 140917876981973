import {
  useQuery,
} from 'react-query';

import middleman from 'helpers/middleman';

export const useBatches = (type: string, status: string, page: number | undefined = undefined) => useQuery(['batches', type, status, page], () => {
  return middleman.promisePost(`/ql/crm/batches/filter${page ? `?page=${page}` : ''}`, {type: type || undefined, status: status || undefined}).then(res => res.data);
}, { enabled: true, refetchOnWindowFocus: false });

export const useBatch = (batchId: string) => useQuery(['batch', batchId], () => {
  return middleman.promiseGet(`/ql/crm/batches/${batchId}`).then(res => res.data);
}, { enabled: !!batchId, refetchOnWindowFocus: false });
