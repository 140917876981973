import React, { useState } from 'react';
import { deconstructFormData } from 'helpers/formscms';
import FormDocuments from '@hubins/components/FormDocuments/FormDocuments';
import Modal from 'components/Modal';
import Button from '@hubins/components/Button';
import Heading from 'components/Heading';
import __ from 'localisation';

type Props = {
  formContent: any;
};

const FormContentDocuments = ({ formContent }) => {

  const [open, setOpen] = useState(false);

  const documentFields = deconstructFormData(formContent).filter((field) => field.type === 'new_documents');
  const files = documentFields.reduce((acc, field) => {
    return [...acc, ...field.files];
  }, []);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className='large'
        icon="Common File Stack by Streamlinehq"
      >
        {__('show_form_files')}
      </Button>
      <Modal open={open} callback={setOpen} closeButton>
        {documentFields.length > 0 ? (
          <FormDocuments files={files} title={__('documents')} />
        ) : (
          <Heading size="4">
            {__('no_documents')}
          </Heading>
        )}

      </Modal>
    </>
  )

};

export default FormContentDocuments;