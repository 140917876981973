import Heading from 'components/Heading';
import React from 'react';
import { dummyProfile } from './dataLayer';


export default function RiskProfile() {
  const dp = dummyProfile();
  return (
    <div>
      <Heading >RiskProfile</Heading>
      {dp.portfolio.name}
      <table>
        <thead>
          <tr>
            <th>Index</th>
            <th>Description</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {dp.fmaRisk.map((fma, i) => (
            <tr key={i}>
              <td>{fma.index}</td>
              <td>{fma.name}</td>
              <td>{fma.riskScore}</td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
}
