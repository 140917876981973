import React from "react";
import { connect } from "react-redux";
import { __route, __ } from "localisation";
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ScrollToTop } from "helpers";
import Layout from "./views/layouts/Layout";
import LayoutDefault from "./views/layouts/LayoutDefault";
import ClientLayout from "./views/layouts/ClientLayout";
import LayoutHeaderMenu from "./views/layouts/LayoutHeaderMenu";
import NoMatch from "views/NoMatch";
import IdleTimer from "./components/IdleTimer";

import LoggedOut from "./views/LoggedOut";
import Dashboard from './views/Dashboard';
import LoginFormTFA from 'views/LoginFormTFA';
import Clients from './views/Clients';
import Client from '../../packages/views/Client';
import History from './views/History';
import Settings from './views/Settings';
import SingleCase from 'views/SingleCase';
import ResetPassword from './views/ResetPassword';
import ChangePassword from './views/ChangePassword';
import { authOperations } from "./state/ducks/auth";
import SourceOfFunds from "./views/SourceOfFunds";
import Files from './views/Files';
import CreateTestApproval from "./views/CreateTestApprovals";
import NewApprovals from "./views/NewApprovals";
import NewApproval from "./views/NewApproval";
import ROYCList from "./views/ROYCList";
import Profiles from "./views/Profiles";
import Profile from "./views/Profile";
import Orders from "./views/Orders";

import CMS from 'views/CMS';
import Edit from 'views/CMS/Edit';
import RiskProfile from "./views/RiskProfile";

import Batch from "./views/Batch";
import Users from "./views/Users";

const Routes = ({
  userRoles,
  authToken,
  updateUser,
  user,
  token,
  tokenExpires,
  logoutUser,
  refreshToken,
  children,
}) => {

  return (
    <Router>
      <IdleTimer
        authToken={token}
        tokenExpires={tokenExpires}
        logoutUser={logoutUser}
        refreshToken={refreshToken}
      />
      {children}
      <ScrollToTop location={useLocation} />
      <Switch>
        <Layout
          layout={LayoutHeaderMenu}
          title="Login"
          exact
          path={__route("root")}
          component={(props) => (
            <LoginFormTFA
              reset
              redirectTo="dashboard"
              authToken={authToken}
              updateUser={updateUser}
              {...props}
            />
          )}
          auth={false}
        />
        <Layout
          layout={LayoutHeaderMenu}
          title={__('reset_password_header')}
          exact
          path={'/reset'}
          component={ResetPassword}
          auth={false}
        />
        <Layout
          layout={LayoutHeaderMenu}
          title={__('reset_password_header')}
          exact
          path={'/reset/password/:token'}
          component={ChangePassword}
          auth={false}
        />
        <Layout
          layout={LayoutHeaderMenu}
          title={"ROYC registrations"}
          exact
          path={'/royc'}
          component={ROYCList}
        />
        <Layout
          layout={LayoutDefault}
          title="TEST"
          exact
          path="/test/create-approval"
          component={CreateTestApproval}
        />
        <Layout
          layout={LayoutDefault}
          title="Dashboard"
          exact
          path="/dashboard"
          component={Dashboard}
        />
        <Layout
          layout={LayoutDefault}
          title="Settings"
          exact
          path="/dashboard/settings"
          component={Settings}
        />
        <Layout
          layout={LayoutDefault}
          title="Approvals"
          exact
          path="/dashboard/approvals"
          component={NewApprovals}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/approvals/:sourceUuid"
          component={NewApproval}
        />
        <Layout
          layout={LayoutDefault}
          title={__("case")}
          exact
          path={__route("case_objectid")}
          component={(props) => (
            <SingleCase
              admin={false}
              id={props.match.params.objectid}
              title={__("case")}
              hideFooter={true}
              {...props}
            />
          )}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/clients"
          component={Clients}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/profiles"
          component={Profiles}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/profiles/:profileUuid"
          component={Profile}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/sof/create/:type/:profileUuid"
          component={SourceOfFunds}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/sof/edit/:type/:sourceId/:profileUuid"
          component={SourceOfFunds}
        />
        <Layout
          layout={ClientLayout}
          title="Clients"
          exact
          withClient
          path="/dashboard/clients/:profileUuid/:portfolioUuid/tabs/:tab?"
          render={(props) => {
            return <Client userRoles={userRoles} {...props} />;
          }}
        />
        <Layout
          layout={LayoutDefault}
          title="Riskprofil"
          exact
          path="/portfolios/:portfolioUuid/risk-profile"
          component={RiskProfile}
        />
        <Layout
          layout={ClientLayout}
          title="Clients"
          exact
          withClient
          path="/dashboard/clients/:profileUuid/:portfolioUuid/history"
          component={History}
        />
        <Layout
          layout={LayoutHeaderMenu}
          title={__("logging_out")}
          exact
          path={__route("logout")}
          auth={false}
          component={LoggedOut}
        />
        {/* Files route */}
        <Layout
          layout={LayoutDefault}
          title="Security CMS"
          exact
          path="/dashboard/files"
          component={Files}
        />

        {/* CMS Routes  */}
        <Layout
          layout={LayoutDefault}
          title="Security CMS"
          exact
          path="/dashboard/cms"
          component={CMS}
        />
        <Layout
          layout={LayoutDefault}
          title="Security CMS"
          exact
          path="/dashboard/cms/:objectid/:language"
          render={(props) => <Edit user={user} {...props} />}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/orders/:tab?"
          component={Orders}
        />
        <Layout
          layout={LayoutDefault}
          exact
          path="/dashboard/batches/:batchId"
          component={Batch}
        />
        <Layout
          layout={LayoutDefault}
          path="/db/users"
          title="Users"
          component={Users}
        />
        <Layout
          layout={LayoutHeaderMenu}
          title="404 - Not found"
          component={NoMatch}
          btnType="logout"
        />

      </Switch>
    </Router>
  );
};

function mapStateToProps(state) {
  return {
    token: state.auth.User.authToken,
    tokenExpires: state.auth.User.tokenExpires,
    userRoles: state.auth.User.userRoles,
    authToken: state.auth.User.authToken,
    user: state.auth.User,
  };
}

const mapDispatchToProps = {
  updateUser: authOperations.updateUser,
  refreshToken: authOperations.refreshToken,
  logoutUser: authOperations.logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps, false)(Routes);
